export enum InvoiceType {
  Appointment,
  Subscription,
}

export enum InvoiceStatus {
  Requested,
  Pending,
  Issued,
  Rejected,
  Canceled
}

export enum InvoiceStatusSpanish {
  "Solicitado" = 0,
  "Procesando" = 1,
  "Emitido" = 2,
  "Rechazado" = 3,
  "Cancelado" = 4
}


export interface InvoiceSlim {
  _id?: string;
  paymentId: string;
  createdAt: Date;
  updatedAt: Date;
  status: InvoiceStatus;
  invoiceType: InvoiceType;
  fileUrl?: string;
}

export interface InvoiceDto extends InvoiceSlim {
  notes?: string;
  updatedBy?: string;
}