import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import momentTz from "moment-timezone";

import {
  InputType,
  EndpointGenerator,
  timezonesInfo,
  ErrorMsg,
  Action,
} from "@mapsy/shared";

import { selectSessionState } from "features/session/session.slice";
import { setToken } from "utils/setToken";
import { useAppSelector } from "hooks";
import { useAxios } from "hooks/useAxios";
import { useAnalytics } from "hooks/useAnalytics";

import { Form } from "interfaces";
import { defaultMaxBirthDate } from "constants/defaultUserValues";
import countryInfo from "../../data/countries_info.json";
import { ValidationProvider } from "providers/FormProvider";
import { AddPatientForTherapistModal } from "components/atoms/AddPatientForTherapistModal";
import COMPONENTS from "constants/componentNames";

interface Props {
  onClose: () => void;
  onNewPatientAdded: () => void;
  open: boolean;
}
const timezone = momentTz.tz.guess();
const defaultEntity = {
  "phone.dial_code": /Mex/g.test(timezone) ? "+52" : "",
  timezone,
};

export const AddPatientForTherapist: React.FC<Props> = ({
  onClose,
  onNewPatientAdded,
  open,
}) => {
  const { postData: invitePatient, isLoading, errorMsg } = useAxios();
  const { createAnalytic } = useAnalytics();
  const { token, profileInfo } = useAppSelector(selectSessionState);
  const [currentTab, setCurrentTab] = useState(0);
  const [entity, setEntity] = useState<any>(defaultEntity);
  const [hasSucceeded, setHasSucceeded] = useState(false);

  const isImportingPatient = currentTab === 0;

  const emailInput: Form = useMemo(
    () => [
      {
        propertyName: "email",
        inputType: InputType.Text,
        placeholder: "Invita a nuevos pacientes vía electrónico",
        required: true,
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 100,
        },
      },
    ],
    []
  );

  const patientInputs: Form = useMemo(
    () => [
      {
        propertyName: "firstName",
        label: "Primer nombre",
        inputType: InputType.Text,
        placeholder: "Primer nombre",
        gridSize: {
          md: 6,
          sm: 6,
          xs: 12,
        },
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 50,
        },
      },
      {
        propertyName: "middleName",
        label: "Segundo nombre",
        inputType: InputType.Text,
        placeholder: "Segundo nombre",
        gridSize: {
          md: 6,
          sm: 6,
          xs: 12,
        },
        validation: {
          isRequired: false,
          minLength: 2,
          maxLength: 50,
        },
      },
      {
        propertyName: "lastName",
        label: "Apellidos",
        inputType: InputType.Text,
        placeholder: "Apellidos",
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 50,
        },
      },
      {
        propertyName: "birthDate",
        label: "Fecha de nacimiento",
        helperText: "Fecha de nacimiento",
        inputType: InputType.Date,
        gridSize: {
          md: 6,
          sm: 6,
          xs: 12,
        },
        datePickerProps: {
          maxDate: defaultMaxBirthDate,
        },
        validation: {
          isRequired: true,
        },
      },
      {
        propertyName: "gender",
        label: "Sexo",
        inputType: InputType.Select,
        placeholder: "Sexo",
        menuItems: [
          {
            label: `Femenino`,
            value: 0,
          },
          {
            label: `Masculino`,
            value: 1,
          },
          {
            label: `Otro`,
            value: 2,
          },
        ],
        gridSize: {
          md: 6,
          sm: 6,
          xs: 12,
        },
        validation: {
          isRequired: true,
        },
      },
      {
        propertyName: "email",
        label: "Correo electrónico",
        placeholder: "Correo electrónico",
        inputType: InputType.Text,
        size: "small",
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 40,
        },
      },
      {
        propertyName: "phone.dial_code",
        label: "",
        inputType: InputType.Autocomplete,
        placeholder: "Lada",
        useOptionIndex: true,
        options: countryInfo
          .map(({ dial_code, es_name, name }, i) => ({
            label: `${es_name || name} (${dial_code})`,
            value: dial_code,
            index: i,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        gridSize: {
          md: 3,
          sm: 3,
          xs: 12,
        },
        validation: {
          isRequired: true,
        },
      },
      {
        propertyName: "phone.number",
        label: "Número telefónico",
        inputType: InputType.Text,
        placeholder: "Número telefónico",
        type: "phone",
        gridSize: {
          md: 9,
          sm: 9,
          xs: 12,
        },
        validation: {
          isRequired: true,
          minLength: 10,
          maxLength: 10,
          formatErrorMsg: ErrorMsg.PhoneFormatNotValid,
        },
      },
      {
        propertyName: "timezone",
        label: "Zona horaria",
        inputType: InputType.Autocomplete,
        placeholder: "Zona horaria",
        useOptionIndex: true,
        options: timezonesInfo.map(({ es_timezone, offset, timezone }, i) => ({
          label: `${es_timezone} (UTC${offset})`,
          value: timezone,
          index: i,
        })),
        validation: {
          isRequired: true,
        },
      },
    ],
    []
  );

  const inputs = useMemo(
    () => (isImportingPatient ? patientInputs : emailInput),
    [isImportingPatient, patientInputs, emailInput]
  );

  const disableSubmitButton = useMemo(
    () =>
      Object.keys(entity).length <
      inputs.filter(({ validation }) => validation?.isRequired).length,
    [entity, inputs]
  );

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (hasSucceeded) {
      if (isImportingPatient) {
        onNewPatientAdded();
      }
      
      interval = setTimeout(() => {
        setEntity({});
        setHasSucceeded(false);
        onClose();
      }, 500);
    }

    return () => {
      clearTimeout(interval);
    };
  }, [hasSucceeded, isImportingPatient]);

  const handleSubmit = useCallback(async () => {
    if (!profileInfo?.id) {
      return;
    }

    const id: string = profileInfo?.id;

    const url = isImportingPatient
      ? EndpointGenerator.TherapistAPI.importPatient(id)
      : EndpointGenerator.TherapistAPI.invitePatientByEmail(id);

    const data = isImportingPatient
      ? {
          ...entity,
          birthDate: moment(entity.birthDate).valueOf(),
        }
      : entity;

    const response = await invitePatient(url, data, setToken(token), {
      409: ErrorMsg.InviteEmailExists,
    });

    if (response) {
      setHasSucceeded(true);
      createAnalytic({
        action: Action.SUBMIT,
        componentName: COMPONENTS.ADD_PATIENT_FOR_THERAPIST,
        data: {
          ...data,
          isImportingPatient,
        },
      });
      return true;
    }
  }, [profileInfo, isImportingPatient, entity, invitePatient]);

  return (
    <ValidationProvider handleSubmit={handleSubmit}>
      <AddPatientForTherapistModal
        inputs={inputs}
        entity={entity}
        setEntity={setEntity}
        errorMsg={errorMsg}
        isLoading={isLoading}
        hasSucceeded={hasSucceeded}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        disableSubmitButton={disableSubmitButton}
        open={open}
        onClose={() => {
          onClose();
          setHasSucceeded(false);
        }}
      />
    </ValidationProvider>
  );
};
