import momentTz from "moment-timezone";
import {
  Appointment,
  AppointmentStatusSpanish,
  COLORS,
  EndpointGenerator,
  formatTimezonesEs,
  GetAllPagedResult,
  ModalitySpanish,
  OrderEnum,
  PaymentStatus,
  ProvidedServiceTypesSpanish,
  UserType,
} from "@mapsy/shared";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { selectSessionState } from "features/session/session.slice";
import { useAxios } from "hooks/useAxios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { capitalizeName } from "utils/capitalize";
import { setToken } from "utils/setToken";
import { CustomLink } from "components/atoms/Link";
import { AppointmentPaymentStatus } from "components/atoms/appointment/AppointmentPaymentStatus";
import { ChangeAppPaymentStatus } from "components/molecules/ChangeAppPaymentStatus";

const APPOINTMENTS_PER_PAGE = 20;
const currentTz = momentTz.tz.guess();
const dateFormat = "DD [de] MMMM [del] YYYY";

export const PatientsAppointmentsHistory = () => {
  const { patientId } = useParams();
  const { getData, errorMsg, isLoading } = useAxios();
  const { token, profileInfo } = useSelector(selectSessionState);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [appointments, setAppointments] = useState<
    Record<number, Appointment[]>
  >({});
  const flatAppointments = useMemo(
    () => Object.values(appointments).flat(),
    [appointments]
  );
  const [emptyAppointmentsList, setEmptyAppointmentsList] = useState(false);

  const observerTarget = useRef(null);

  const fetchAppointments = useCallback(
    async (
      id: string,
      patientId: string,
      page: number,
      signal: AbortSignal,
      token: string
    ) => {
      const endpoint = EndpointGenerator.AppointmentAPI.byTherapist(
        {
          orderBy: "date",
          order: OrderEnum.DESC,
          patientId,
          page,
          limit: APPOINTMENTS_PER_PAGE,
        },
        id
      );

      const response: GetAllPagedResult<Appointment> = await getData(endpoint, {
        signal,
        ...setToken(token),
      });

      if (!response?.results) {
        return;
      }

      const { results, totalPages } = response;

      if (page === 1 && !results.length) {
        setEmptyAppointmentsList(true);
      }

      setAppointments((_appointments) => ({
        ..._appointments,
        [page]: results,
      }));

      setTotalPages(totalPages);
    },
    [token, profileInfo]
  );

  const handleNextPage = useCallback(() => {
    if (totalPages === page) {
      return;
    }
    setPage(page + 1);
  }, [page, totalPages]);

  useEffect(() => {
    if (!token || !profileInfo?.id || !patientId) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;
    fetchAppointments(profileInfo.id, patientId, page, signal, token);

    return () => {
      controller.abort();
    };
  }, [profileInfo, patientId, page, token]);

  const handleUpdateAppointment = useCallback(
    (appToUpdate: Appointment, newPaymentStatus: PaymentStatus) => {
      for (const page in appointments) {
        if (Object.prototype.hasOwnProperty.call(appointments, page)) {
          const appsInPage = appointments[page];

          for (let i = 0; i < appsInPage.length; i++) {
            const app = appsInPage[i];

            if (app._id === appToUpdate._id) {
              const appCopy: Appointment = {
                ...app,
                paymentStatus: newPaymentStatus,
              };
              const appsInPageCopy = [...appsInPage];
              appsInPageCopy[i] = appCopy;

              setAppointments((_appointments) => ({
                ..._appointments,
                [page]: appsInPageCopy,
              }));
              return;
            }
          }
        }
      }
    },
    [appointments]
  );

  useEffect(() => {
    const currentTarget = observerTarget.current;
    if (!currentTarget) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          handleNextPage();
        }
      },
      { threshold: 0 }
    );

    observer.observe(currentTarget);
    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [observerTarget, handleNextPage]);

  return (
    <Grid item xs={12}>
      <Grid container sx={{ flexDirection: "column", gap: 3 }}>
        {emptyAppointmentsList ? (
          <Grid item xs={12}>
            <Box
              sx={{
                padding: { xs: "20px 10px", md: "40px" },
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                border: {
                  md: `1px solid ${COLORS.BLUE_1}`,
                  xs: "1px solid rgba(200, 224, 233, 0.5)",
                },
                boxShadow: {
                  md: "0px 2px 1px rgba(0, 0, 0, 0.05)",
                  xs: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                },
                borderRadius: "24px",
                gap: { md: 4, xs: 2 },
                textAlign: "center",
              }}
            >
              <Typography variant="h5">
                Aún no has agendado una cita con este paciente
              </Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Typography>Puedes agendarle una cita desde</Typography>
                <CustomLink
                  to="/therapist/my_calendar"
                  underline
                  analyticsLabel="Mi agenda"
                >
                  <Typography>Mi agenda</Typography>
                </CustomLink>
              </Box>
            </Box>
          </Grid>
        ) : (
          flatAppointments.map((appointment, index) => {
            const {
              _id,
              therapist,
              patient,
              date,
              providedService,
              locationId,
              paymentStatus,
            } = appointment;

            const location = therapist?.locations.find(
              ({ _id }) => _id === locationId
            );
            const momentCurrentTzDate = momentTz(date).tz(currentTz);
            const momentPatientTzDate =
              patient?.timezone &&
              patient.timezone !== currentTz &&
              momentTz(date).tz(patient?.timezone);

            return (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  borderRadius: "10px",
                  border: "1px solid rgba(200, 224, 233, 0.5)",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  flexWrap: "wrap",
                  minHeight: "170px",
                  p: { md: 2, xs: 1 },
                }}
                key={`card-appointment-${_id}`}
                ref={
                  index === flatAppointments.length - 1 ? observerTarget : null
                }
              >
                <Grid container>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      px: { md: 2, xs: 1.5 },
                    }}
                  >
                    <Typography variant="h4" sx={{ fontWeight: 500 }}>
                      {patient?.firstName && patient.lastName
                        ? capitalizeName([
                            patient?.firstName,
                            patient?.middleName,
                            patient?.lastName,
                          ])
                        : "Paciente no encontrado"}
                    </Typography>
                    {providedService && location && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: 1,
                        }}
                      >
                        <Typography variant="body2">
                          <Typography
                            sx={{ color: COLORS.BLUE_1 }}
                            component="span"
                            variant="body2"
                          >
                            Terapia:
                          </Typography>{" "}
                          {
                            ProvidedServiceTypesSpanish[
                              providedService.serviceType
                            ]
                          }
                        </Typography>
                        <Typography variant="body2">
                          <Typography
                            sx={{ color: COLORS.BLUE_1 }}
                            component="span"
                            variant="body2"
                          >
                            Modalidad:
                          </Typography>{" "}
                          {ModalitySpanish[location.modality] ||
                            "Modalidad no disponible"}
                        </Typography>
                        <Typography variant="body2">
                          <Typography
                            sx={{ color: COLORS.BLUE_1 }}
                            component="span"
                            variant="body2"
                          >
                            Costo:
                          </Typography>{" "}
                          ${providedService.price} {providedService.currency}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sx={{
                      mt: { xs: 1 },
                      py: { xs: 1 },
                      px: { md: 2, xs: 1 },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      border: "0.89px solid rgba(223, 223, 223, 1)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography variant="body2">
                      <Typography
                        sx={{ color: COLORS.BLUE_1 }}
                        component="span"
                        variant="body2"
                      >
                        Fecha:
                      </Typography>{" "}
                      {momentCurrentTzDate.format(dateFormat)}
                    </Typography>
                    <Typography variant="body2">
                      <Typography
                        sx={{ color: COLORS.BLUE_1 }}
                        component="span"
                        variant="body2"
                      >
                        Hora:
                      </Typography>{" "}
                      {momentCurrentTzDate.format("HH:mm a")}{" "}
                      <Typography variant="caption">
                        ({formatTimezonesEs(currentTz)})
                      </Typography>
                    </Typography>
                    {momentPatientTzDate && patient?.timezone && (
                      <>
                        <Typography variant="body2">
                          <Typography
                            sx={{ color: COLORS.BLUE_1 }}
                            component="span"
                            variant="body2"
                          >
                            Hora (en la zona horaria del paciente):
                          </Typography>{" "}
                          {momentPatientTzDate.format("HH:mm a")} (
                          {formatTimezonesEs(patient.timezone)})
                        </Typography>
                      </>
                    )}
                    <AppointmentPaymentStatus
                      appointmentId={_id}
                      paymentStatus={paymentStatus}
                      userType={UserType.Therapist}
                    />
                    <ChangeAppPaymentStatus
                      appointment={appointment}
                      onPaymentStatusUpdated={handleUpdateAppointment}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        )}
        {errorMsg && (
          <Grid xs={12}>
            <Typography variant="h5" sx={{ mb: 1, color: COLORS.TEXT_RED }}>
              {errorMsg}
            </Typography>
          </Grid>
        )}
        {isLoading && (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress sx={{ color: COLORS.BLUE_1 }} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
