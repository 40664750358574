import React, { ChangeEvent, useCallback } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { formatMonthYear } from "utils/cardUtils";

type Props = TextFieldProps;
const MonthYearInput: React.FC<Props> = ({
  onChange,
  value,
  ...textFieldProps
}) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      e.target.value = formatMonthYear(e.target.value);
      onChange?.(e);
    },
    [onChange]
  );

  return (
    <TextField
      value={value}
      onChange={handleChange}
      multiline={false}
      inputProps={{
        maxLength: 5,
      }}
      {...textFieldProps}
    />
  );
};

export default MonthYearInput;
