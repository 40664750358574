import { useMemo, useEffect, useCallback, useState } from "react";
import { CircularProgress, Container, Grid, Typography } from "@mui/material";
import { Action, COLORS, Therapist, UserType } from "@mapsy/shared";
import COMPONENTS from "constants/componentNames";
import { selectSessionState } from "features/session/session.slice";
import { useAnalytics } from "hooks/useAnalytics";
import { useGetStartedForms } from "hooks/useGetStartedForms";
import { HandleSubmitParams, Entity } from "interfaces";
import { SubmitOnChangeProvider } from "providers/SubmitOnChangeProvider";
import { useSelector } from "react-redux";
import { useAuth } from "hooks/useAuthAPI";
import { InputField } from "components/atoms/InputField";
import {
  selectTherapistState,
  setTherapist,
} from "features/therapist/therapist.slice";
import { AlertInline } from "components/atoms/AlertInline";
import { ALERT_TIMER } from "layouts/MyProfileLayout";
import { useAppDispatch } from "hooks";

interface Props {
  userType: UserType;
}

const Settings: React.FC<Props> = ({ userType }) => {
  const dispatch = useAppDispatch();
  const { createAnalytic } = useAnalytics();
  const { profileInfo } = useSelector(selectSessionState);
  const { therapist } = useSelector(selectTherapistState);
  const { getUserInfo, isLoading, updateUserInfo, errorMsg } = useAuth();

  const { NotificationsPreferences } = useGetStartedForms();
  const [updatedFields, setUpdatedFields] = useState<Partial<Therapist>>();
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [hasSucceeded, setHasSucceeded] = useState(false);

  const inputs = useMemo(
    () => NotificationsPreferences,
    [NotificationsPreferences]
  );

  const fetchUserInfo = useCallback(async () => {
    if (!profileInfo?.id) {
      return;
    }

    const data = await getUserInfo(profileInfo.id, profileInfo.type);
    dispatch(setTherapist(data));
  }, [profileInfo]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (hasSucceeded) {
      intervalId = setTimeout(() => {
        setHasSucceeded?.(false);
      }, ALERT_TIMER);
    }
    return () => clearTimeout(intervalId);
  }, [hasSucceeded]);

  const submitParams: HandleSubmitParams = useMemo(
    () => ({
      values: updatedFields as Entity,
    }),
    [updatedFields]
  );

  const handleSubmit = useCallback(
    async ({ values }: HandleSubmitParams) => {
      if (!profileInfo?.id) {
        return;
      }

      setIsUpdateLoading(true);

      const id = await updateUserInfo(
        { _id: profileInfo?.id, fieldsToUpdate: values },
        UserType.Therapist
      );
      createAnalytic({
        action: Action.SUBMIT,
        componentName: COMPONENTS.SETTINGS,
        data: { values },
      });
      if (id) {
        await fetchUserInfo();
        setIsUpdateLoading(false);
        setHasSucceeded(true);
        setUpdatedFields(undefined);
      }
    },
    [profileInfo]
  );

  const handleChange = useCallback(
    (propertyName: string, value: any, context: any) => {
      setUpdatedFields({
        [`notificationPreferences.${propertyName}`]: value,
      });
    },
    []
  );

  if (isLoading && !therapist) {
    return (
      <Container>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <CircularProgress />
        </Grid>
      </Container>
    );
  }

  return (
    <SubmitOnChangeProvider
      stateToObserve={updatedFields}
      helperToSubmitOnChange={handleSubmit}
      submitParams={submitParams}
      delay={1500}
    >
      <Container maxWidth="lg" sx={{ py: 5 }}>
        <Grid container>
          <AlertInline open={isUpdateLoading} msg="Guardando..." />
          <AlertInline
            open={hasSucceeded}
            msg="Información actualizada exitosamente."
          />
          {errorMsg && (
            <Grid
              item
              xs={12}
              md={10}
              sx={{ justifyContent: "end", display: "flex" }}
            >
              <Typography>{errorMsg}</Typography>
            </Grid>
          )}
          <Grid
            item
            sx={{
              py: 1,
            }}
            xs={12}
          >
            <Typography variant="h2" sx={{ mb: 1 }}>
              Configuración
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              borderBottom: `solid 3px ${COLORS.BLUE_1}`,
              my: 2,
            }}
            md={6}
            xs={12}
          ></Grid>
          <Grid
            item
            sx={{
              py: 1,
              mb: 1,
            }}
            xs={12}
          >
            <Typography variant="h6">Notificaciones</Typography>
            <Typography variant="body2">
              Aquí puedes personalizar las notificaciones que quieres recibir.
            </Typography>
          </Grid>
          {inputs.map(({ gridSize, propertyName, ...rest }, i) => (
            <Grid item xs={12} key={`grid-item-topics-${i}`}>
              <InputField
                backgroundMode="transparent"
                propertyName={propertyName}
                value={therapist?.notificationPreferences as any}
                handleChange={handleChange}
                {...rest}
                label=""
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </SubmitOnChangeProvider>
  );
};

export default Settings;
