import _ from "lodash";

import { ErrorMsg, ErrorsObj, InputType } from "@mapsy/shared";
import {
  CustomValidator,
  Entity,
  ProjectedInputMetadata,
  ValidateInputsArgs,
  ValidationProps,
} from "interfaces";
import { regexLibrary } from "./regex/library";
import { isEmpty } from "./object.utils";
import { isValidCardNumber, isValidExpirationDate } from "./cardUtils";

export const isLowerThan =
  (lowerNumber: any, lowerThan: string) =>
  (propertyName: string, higherNumber: any) => {
    if (
      propertyName === lowerThan &&
      isNaN(+higherNumber) &&
      isNaN(+lowerNumber)
    ) {
      return +lowerNumber < +higherNumber;
    }
  };

export const validateInput = (
  value: Entity,
  propertyName: string,
  validation: ValidationProps,
  errors: ErrorsObj,
  inputType?: InputType
) => {
  let isCurrentValueLowerThan: any = undefined;
  const {
    formatErrorMsg,
    isRequired,
    maxLength,
    minLength,
    regex,
    lowerThan,
    min,
    max,
  } = validation;

  if (
    (_.isNull(value) || _.isUndefined(value) || isEmpty(value)) &&
    isRequired
  ) {
    errors[propertyName] = ErrorMsg.IsRequired;
    return false;
  }

  if (lowerThan && value !== undefined) {
    isCurrentValueLowerThan = isLowerThan(lowerThan, value.toString());
  }

  if (isCurrentValueLowerThan && isCurrentValueLowerThan(propertyName, value)) {
    errors[propertyName] = ErrorMsg.LowDate;
    return false;
  }

  if (value && minLength && value.toString().length < minLength) {
    errors[propertyName] =
      `${ErrorMsg.SmallerThanMinLength} Al menos ${minLength} caracteres`;
    return false;
  }

  if (value && maxLength && value.toString().length > maxLength) {
    errors[propertyName] =
      `${ErrorMsg.LargetThanMaxLength} Máximo ${maxLength} caracteres`;
    return false;
  }

  const regexToTest =
    regex || regexLibrary[propertyName] || regexLibrary.default;

  const inputTypeToValidate =
    inputType !== undefined &&
    [InputType.Text, InputType.Textarea].includes(inputType);

  let isValid = inputTypeToValidate
    ? regexToTest?.test(value?.toString())
    : true;

  if (inputType === InputType.MonthYear && propertyName === "expirationDate") {
    isValid = isValidExpirationDate(String(value));
  }

  if (inputType === InputType.CardNumber) {
    isValid = isValidCardNumber(String(value));
  }

  if (!isValid) {
    errors[propertyName] = formatErrorMsg || ErrorMsg.FormatNotValid;
    return false;
  }

  const valueToNumber = Number(value);
  if (!isNaN(valueToNumber)) {
    if (min !== undefined && min > valueToNumber) {
      errors[propertyName] =
        `${ErrorMsg.NumberLowerThanMin} Debe ser mayor a ${min}`;
      return false;
    }

    if (max !== undefined && max < valueToNumber) {
      errors[propertyName] =
        `${ErrorMsg.NumberHigherThanMin} Debe ser menor a ${max}`;
      return false;
    }
  }

  return true;
};

/**
 * Iterates for each key-value pair of the object values and check input configuration on validation prop. Property name of inputs should be equal to the keys of values object.
 *
 * Sets the errors object as param in setError function with a key-value pair for each input name and the error associated if any.
 * @returns A boolean flag if any value is not valid
 */
export const areInputsValid: (args: ValidateInputsArgs, customValidations?: CustomValidator | null) => boolean = ({
  inputs,
  values,
  setErrors,
  parent = null,
}, custom = null) => {
  let errorsObj: ErrorsObj = {};
  let allInputsValid = true;

  const projectedSetErrors = (err: ErrorsObj) => {
    errorsObj = { ...errorsObj, ...err };
  };

  for (const input of inputs) {
    const { propertyName, validation, inputType } = input;

    if (input.inputType === InputType.Projected) {
      const { inputs: projectedInputs } = input as ProjectedInputMetadata;
      const projectedAreValid = areInputsValid({
        inputs: projectedInputs,
        values: _.get(values, propertyName) as Entity,
        setErrors: projectedSetErrors,
        parent: propertyName,
      }, custom);
      allInputsValid = allInputsValid && projectedAreValid;
      continue;
    }

    if (!validation || !validation?.isRequired) {
      continue;
    }

    if (parent) {
      if (_.isArray(values)) {
        for (const index in values) {
          const nestedValue = values[index];
          const value = _.get(nestedValue, propertyName);
          const isValid = validateInput(
            value as Entity,
            `${parent}.${index}.${propertyName}`,
            validation,
            errorsObj,
            inputType
          );
          allInputsValid = allInputsValid && isValid;
        }
      } else if (_.isObject(values)) {
        const value = _.get(values, propertyName);
        const isValid = validateInput(
          value as Entity,
          `${parent}.${propertyName}`,
          validation,
          errorsObj,
          inputType
        );
        allInputsValid = allInputsValid && isValid;
      }
    } else {
      const value = _.get(values, propertyName);
      let isValid = false;
      if (custom && custom[propertyName]) {
        isValid = custom[propertyName](value as Entity, errorsObj)
      } else {
        isValid = validateInput(
          value as Entity,
          propertyName,
          validation,
          errorsObj,
          inputType
        );
      }
      
      allInputsValid = allInputsValid && isValid;
    }
  }

  setErrors(errorsObj);
  return allInputsValid;
};
