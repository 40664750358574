import { useCallback, Suspense } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Outlet, ScrollRestoration, UIMatch, Location } from "react-router-dom";
import logo from "assets/img/logo_without_label.webp";

const excludedPathsFromRestoration: string[] = ["/contact", "/about-us"];
export const MainLayout = () => {
  const getKey = useCallback((location: Location, matches: UIMatch[]) => {
    return excludedPathsFromRestoration.includes(location.pathname)
      ? location.key
      : location.pathname;
  }, []);
  return (
    <Box sx={{ height: { xs: "100%" } }}>
      <Suspense
        fallback={
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center ",
              gap: { md: 3, xs: 1 },
              p: 2,
            }}
          >
            <Box component="img" src={logo} alt="mapsy-logo" width={130}></Box>
            <CircularProgress />
          </Box>
        }
      >
        <Outlet />
      </Suspense>
      <ScrollRestoration getKey={getKey} />
    </Box>
  );
};
