export enum MapsyNotificationType {
  NewAppt,
  CancelAppt,
  Generic,
  ReminderOnDayBeforeAtNoon,
  ReminderOneHourBefore,
  Review,
  CompleteProfileTherapist,
  ValidateEmailTherapist,
  ValidateProfessionalId,
  UploadProfilePicTherapist,
  ApprovedProfessionalId,
  RejectedProfessionalId,
}

export enum SgTemplate {
  WELCOME,
  CONTACT,
  RESET_PASSWORD,
  VALIDATE_EMAIL,
  NEW_APPOINTMENT_PATIENT,
  NEW_APPOINTMENT_THERAPIST,
  REMINDER_ONE_HOUR_BEFORE_PATIENT,
  REMINDER_ONE_HOUR_BEFORE_THERAPIST,
  REMINDER_ONE_DAY_BEFORE_PATIENT,
  REMINDER_ONE_DAY_BEFORE_THERAPIST,
  CANCELLED_APPOINTMENT_PATIENT,
  REVIEW_REQUEST,
  REMINDER_TO_COMPLETE_PROFILE_THERAPIST,
  REMINDER_TO_VALIDATE_EMAIL_THERAPIST,
  REMINDER_TO_VALIDATE_PROFESSIONAL_ID,
  REMINDER_TO_UPLOAD_PROFILE_PIC_THERAPIST,
  APPROVED_PROFESSIONAL_ID,
  REJECTED_PROFESSIONAL_ID,
  INVITATION_TO_PATIENT,
}
