import React, { useCallback, useMemo, useState } from "react";

import { Tooltip } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import {
  COLORS,
  EndpointGenerator,
  ErrorMsg,
  StylesEnum,
  UserType,
} from "@mapsy/shared";

import { CustomButton } from "components/atoms/Button";
import { useAxios } from "hooks/useAxios";
import { setToken } from "utils/setToken";
import { useAppSelector } from "hooks";
import { selectSessionState } from "features/session/session.slice";

const SendInviteButton: React.FC<{
  userId: string;
  userType: UserType;
  userEmail: string;
}> = ({ userId, userType, userEmail }) => {
  const { isLoading, postData: sendInvite, errorMsg } = useAxios();
  const [initiated, setInitiated] = useState(false);
  const [sent, setSent] = useState(false);
  const [failed, setFailed] = useState(false);
  const { token, profileInfo } = useAppSelector(selectSessionState);

  const validParams = useMemo(() => {
    if (!userId || !UserType[userType] || !userEmail) {
      return false;
    }

    return true;
  }, [userId, userType, userEmail]);

  const tooltipTitle = useMemo(() => {
    if (!sent && !failed) {
      return "Enviar email de invitación";
    }

    if (failed) {
      return "No se pudo enviar la invitación";
    }

    return "Invitación enviada";
  }, [sent, failed]);

  const send = useCallback(async (email: string) => {
    if (!profileInfo?.id) {
      return;
    }

    const url =
      userType === UserType.Patient
        ? EndpointGenerator.TherapistAPI.invitePatientByEmail(profileInfo?.id)
        : EndpointGenerator.TherapistAPI.invitePatientByEmail(profileInfo?.id);
    const response = await sendInvite(url, { email }, setToken(token), {
      409: ErrorMsg.InviteEmailExists,
    });

    if (response && response.status) {
      setSent(true);
      return;
    }

    setFailed(true);
  }, []);

  if (!validParams) {
    return <></>;
  }

  return (
    <Tooltip title={tooltipTitle}>
      <div>
        <CustomButton
          sx={{
            color: COLORS.PURE_WHITE,
            padding: "12px",
            minWidth: "50px",
            "& > .MuiButton-icon.MuiButton-endIcon": {
              ml: 0,
            },
          }}
          customStyle={StylesEnum.primary}
          isLoading={isLoading && initiated}
          hasSucceeded={sent}
          hasFailed={failed}
          analyticsLabel="Invite Email - Patient"
          onClick={() => {
            setInitiated(true);
            send(userEmail);
          }}
          loadingLabel=""
          endIcon={initiated ? <></> : <SendIcon />}
        ></CustomButton>
      </div>
    </Tooltip>
  );
};

export default SendInviteButton;
