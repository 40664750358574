import _ from "lodash";
import React, { SyntheticEvent, useCallback, useEffect, useMemo } from "react";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";

import { StylesEnum } from "@mapsy/shared";

import COLORS from "constants/colors";

import { CustomButton } from "./Button";
import { ModalLayout } from "layouts/ModalLayout";
import { InputField } from "./InputField";

import { Form } from "interfaces";
import { useValidation } from "providers/FormProvider";

export const DEFAULT_APPOINTMENT_DURATION = 60;

function a11yProps(index: number) {
  return {
    id: `invita-patient-tabpanel-${index}`,
    "aria-controls": `invita-patient-tabpanel-${index}`,
  };
}

interface Props {
  onClose: () => void;
  open: boolean;
  inputs: Form;
  entity: any;
  setEntity: (values: any) => void;
  hasSucceeded: boolean;
  isLoading: boolean;
  errorMsg: string | null;
  currentTab: number;
  setCurrentTab: (tab: number) => void;
  disableSubmitButton: boolean;
}

export const AddPatientForTherapistModal: React.FC<Props> = ({
  entity,
  errorMsg,
  hasSucceeded,
  inputs,
  isLoading,
  onClose,
  open,
  setEntity,
  currentTab,
  setCurrentTab,
  disableSubmitButton,
}) => {
  const isImportingPatient = currentTab === 0;
  const { resetErrors } = useValidation();

  const buttonLabel = useMemo(() => {
    return isImportingPatient ? "Agregar" : "Invitar";
  }, [isImportingPatient]);

  const resetForm = useCallback(() => {
    setEntity({});
    resetErrors();
  }, []);

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, []);

  const handleChangeTab = useCallback((e: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    setEntity({});
    resetErrors();
  }, []);

  const handleChange = useCallback((propertyName: string, value: any) => {
    setEntity((prev: any) => {
      return {
        ...prev,
        [propertyName]: value,
      };
    });
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (hasSucceeded) {
      interval = setTimeout(() => {
        handleClose();
      }, 200);
    }

    return () => {
      clearTimeout(interval);
    };
  }, [hasSucceeded]);

  return (
    <ModalLayout isOpen={open} onClose={handleClose} maxWidthContainer="sm">
      <Grid container sx={{ padding: 2, gap: { md: 1, xs: 0.5 } }}>
        <Grid item xs={12}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              py: 0,
              "& .MuiTabs-scroller": {
                overflowX: "auto !important",
              },
            }}
          >
            <Tabs
              value={currentTab}
              onChange={handleChangeTab}
              aria-label="new appointment tabs"
              className="transparent-scroll"
            >
              {["Agregar Paciente", "Invitar por link"].map((tab, i) => (
                <Tab
                  key={`add-patient-tabpanel-${i}-${tab}`}
                  label={
                    <Typography
                      sx={{ color: COLORS.BLUE_1, fontWeight: 500 }}
                      variant="h5"
                    >
                      {tab}
                    </Typography>
                  }
                  {...a11yProps(i)}
                  sx={{
                    fontSize: { md: "0.9rem", xs: "0.7rem" },
                    textTransform: "inherit",
                    "&.Mui-selected": {
                      color: COLORS.BLUE_1,
                      borderBottomColor: COLORS.BLUE_1,
                    },
                  }}
                />
              ))}
            </Tabs>
          </Box>
        </Grid>
        <Grid id={`add-patient-tabpanel-form-fieldset`} container>
          {inputs.map(
            (
              { gridSize = { xs: 12, md: 12, sm: 12 }, propertyName, ...rest },
              i
            ) => (
              <Grid
                xs={gridSize.xs}
                md={gridSize.md}
                id={`row-input-container-${propertyName}-${i}`}
                item
                key={`row-input-container-${propertyName}-${i}`}
                sx={{ px: 1, my: 1.5 }}
              >
                <Grid container sx={{ flexDirection: "column" }}>
                  {!isImportingPatient && (
                    <Typography sx={{ mb: 2 }} variant="body2">
                      Invita a nuevos pacientes a que sean parte de Mapsy aquí.
                    </Typography>
                  )}
                  <Grid item>
                    <InputField
                      backgroundMode="transparent"
                      propertyName={propertyName}
                      value={_.get(entity, propertyName, "")}
                      handleChange={handleChange}
                      {...rest}
                      label=""
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          )}
        </Grid>

        {errorMsg && (
          <Grid item xs={12}>
            <Typography>{errorMsg}</Typography>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sx={{ mt: 3, display: "flex", justifyContent: "right" }}
        >
          <CustomButton
            customStyle={StylesEnum.primary}
            sx={{ px: 1 }}
            hasSucceeded={hasSucceeded}
            type="submit"
            isLoading={isLoading}
            disabled={disableSubmitButton}
          >
            {buttonLabel}
          </CustomButton>
        </Grid>
      </Grid>
    </ModalLayout>
  );
};
