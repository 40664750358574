import React, { ChangeEvent, useCallback } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { formatCvv } from "utils/cardUtils";

type Props = TextFieldProps;
const CvvInput: React.FC<Props> = ({ onChange, value, ...textFieldProps }) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      e.target.value = formatCvv(e.target.value);
      onChange?.(e);
    },
    [onChange]
  );

  return (
    <TextField
      value={value}
      onChange={handleChange}
      multiline={false}
      inputProps={{
        maxLength: 4,
      }}
      InputProps={{
        type: "password",
      }}
      {...textFieldProps}
    />
  );
};

export default CvvInput;
