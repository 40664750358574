import { Box, Container, Grid, Typography } from "@mui/material";
import logo from "assets/img/logo_without_label.webp";
import arrowBack from "assets/svg/arrow-back.svg";
import { TitledLayout } from "layouts/TitledLayout";
import { useNavigate } from "react-router-dom";
import COLORS from "constants/colors";
import { useCallback } from "react";
import { resetTherapistsPagination } from "features/reason/reasonSice";
import { useAppDispatch } from "hooks";
import { CustomLink } from "components/atoms/Link";

const suggestionsList = [
  "Profundiza acerca de tu motivo de consulta.",
  "Amplia los criterios de búsqueda a temáticas relacionadas.",
];

export const EmptyTherapistList = () => {
  const nav = useNavigate();
  const dispatch = useAppDispatch();

  const handleClickShowAllTherapists = useCallback(() => {
    dispatch(resetTherapistsPagination());
    nav("/all-therapists");
  }, []);

  return (
    <Box>
      <Container sx={{ display: "flex", justifyContent: "center" }}>
        <TitledLayout
          containerSx={{
            mt: { md: 4, xs: 2 },
            p: 2,
            gap: 0,
            justifyContent: "flex-start",
          }}
        >
          <Grid item xs={1}>
            <CustomLink to="/" aria-roledescription="back-icon" analyticsLabel="Arrow Back">
              <img src={arrowBack} alt="back-icon" width="25px" />
            </CustomLink>
          </Grid>
          <Grid item xs={12} sx={{ mt: -2 }}>
            <Grid
              container
              sx={{
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 3,
              }}
            >
              <Grid item xs={4} md={2}>
                <CustomLink to="/" aria-roledescription="mapsy-logo" analyticsLabel="Mapsy Logo">
                  <img src={logo} alt="mapsy-logo" width="100%" />
                </CustomLink>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  gap: 3,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h4">
                  No se encontró ningún terapeuta especialista las temáticas que
                  ingresaste en tu búsqueda.
                </Typography>
                <Box sx={{ fontWeight: 500 }}>
                  Sugerencias:
                  <ul>
                    {suggestionsList.map((sug, i) => (
                      <li key={`suggestion-${i}`}>
                        <Typography variant="h4" sx={{ fontWeight: 400 }}>
                          {sug}
                        </Typography>
                      </li>
                    ))}
                    <li>
                      <Typography variant="h4" sx={{ fontWeight: 400 }}>
                        Navega por nuestra{" "}
                        <Typography
                          component={"span"}
                          variant="h4"
                          sx={{
                            color: COLORS.BLUE_1,
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={handleClickShowAllTherapists}
                        >
                          lista completa
                        </Typography>{" "}
                        de terapeutas para encontrar otras áreas de atención que
                        puedan ser de tu interés.
                      </Typography>
                    </li>
                  </ul>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    color: COLORS.BLUE_1,
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                  onClick={handleClickShowAllTherapists}
                >
                  Ir a la lista de terapeutas
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </TitledLayout>
      </Container>
    </Box>
  );
};
