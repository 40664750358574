import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TitleUpdater: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        document.title = "Mapsy Ψ";
        break;
      case "/reason":
        document.title = "¿Cuál es tu motivo de consulta? - Mapsy Ψ";
        break;
      case "/suggested-therapists":
        document.title = "Terapeutas recomendados en Mapsy Ψ";
        break;
      case "/all-therapists":
        document.title = "Terapeutas en Mapsy Ψ";
        break;
      case "/appointment/confirmation":
        document.title = "Confirma tu cita - Mapsy Ψ";
        break;
      case "/appointment/payment":
        document.title = "Realiza el pago de tu cita - Mapsy Ψ";
        break;
      case "/join":
        document.title = "Únete - Mapsy Ψ";
        break;
      case "/terms-and-conditions":
        document.title = "Términos y condiciones - Mapsy Ψ";
        break;
      case "/privacy-policy":
        document.title = "Aviso de privacidad - Mapsy Ψ";
        break;
      case "/about-us":
        document.title = "Quiénes somos - Mapsy Ψ";
        break;
      case "/contact":
        document.title = "Contáctanos - Mapsy Ψ";
        break;
      case "/users/signin":
        document.title = "Inicia sesión - Mapsy Ψ";
        break;
      case "/users/signup":
        document.title = "Regístrate - Mapsy Ψ";
        break;
      case "/users/forgot-password":
        document.title = "Olvidé mi contraseña - Mapsy Ψ";
        break;
      case "/patient/my_profile":
        document.title = "Mi perfil - Mapsy Ψ";
        break;
      case "/patient/my_appointments":
        document.title = "Mis citas - Mapsy Ψ";
        break;
      case "/patient/review":
        document.title = "Opinión - Mapsy Ψ";
        break;
      case "/therapist/my_profile":
        document.title = "Mi perfil de terapeuta - Mapsy Ψ";
        break;
      case "/therapist/my_calendar":
        document.title = "Mi agenda - Mapsy Ψ";
        break;
      case "/therapist/my_patients":
        document.title = "Mis pacientes - Mapsy Ψ";
        break;
      case "/therapist/my_locations":
        document.title = "Mis consultorios - Mapsy Ψ";
        break;
      default:
        document.title = "Mapsy Ψ";
        break;
    }
  }, [location.pathname]);

  return null;
};

export default TitleUpdater;
