import { COLORS, PaymentStatus, UserType } from "@mapsy/shared";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { CustomLink } from "../Link";

interface Props {
  paymentStatus: PaymentStatus;
  appointmentId: string;
  userType: UserType;
}
export const AppointmentPaymentStatus: React.FC<Props> = ({
  paymentStatus,
  appointmentId,
  userType,
}) => {
  if (paymentStatus === PaymentStatus.Completed) {
    return (
      <Stack>
        <Typography variant="body2" sx={{ color: COLORS.SUCCESS }}>
          Pago realizado
        </Typography>

        {userType === UserType.Patient && (
          <CustomLink
            to={`/patient/invoice/${appointmentId}`}
            underline
            analyticsLabel="solicitar factura"
            style={{ width: "fit-content" }}
          >
            <Typography
              sx={{ fontWeight: 500, color: COLORS.BLUE_1 }}
              variant="caption"
              component="span"
            >
              Solicitar Factura
            </Typography>
          </CustomLink>
        )}
      </Stack>
    );
  }

  if (paymentStatus === PaymentStatus.Failed) {
    return (
      <Typography variant="body2" sx={{ color: COLORS.ERROR }}>
        Pago fallido
      </Typography>
    );
  }

  return (
    <Typography variant="body2" sx={{ color: COLORS.ERROR }}>
      Pago pendiente
    </Typography>
  );
};
