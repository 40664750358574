import { APINames, TokenType, UserType } from "../..";
import BaseAPI from "./BaseEndpointAPI";

class TokenAPI extends BaseAPI {
  constructor() {
    super(APINames.TOKEN);
  }

  validateToken(token: string, email: string, userType: UserType, tokenType: TokenType) {
    const query = new URLSearchParams({ email, userType, tokenType } as any);
    return `${this.baseURL}/reset-password/${token}?${query}`;
  }

  validateEmail(token: string, email: string, userType: UserType, tokenType: TokenType) {
    const query = new URLSearchParams({ email, userType, tokenType } as any);
    return `${this.baseURL}/validate-email/${token}?${query}`;
  }

  validateInvite(token: string, email: string, userType: UserType, tokenType: TokenType) {
    const query = new URLSearchParams({ email, userType, tokenType } as any);
    return `${this.baseURL}/validate-invite/${token}?${query}`;
  }
}

const tokenAPI = new TokenAPI();
export default tokenAPI;
