import { CardNumberType } from "interfaces";

export const isValidCardNumber = (cardNumber: string): boolean => {
  const digits = cardNumber.replace(/\s/g, ""); // Remove spaces
  let sum = 0;
  let shouldDouble = false;

  // Traverse digits from right to left
  for (let i = digits.length - 1; i >= 0; i--) {
    let digit = parseInt(digits[i], 10);

    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) digit -= 9; // Subtract 9 if digit is greater than 9
    }

    sum += digit;
    shouldDouble = !shouldDouble; // Alternate doubling
  }

  return sum % 10 === 0; // Card is valid if the sum is divisible by 10
};

export const formatCardNumber = (value: string): string =>
  value
    .replace(/\D/g, "")
    .replace(/(\d{4})/g, "$1 ")
    .trim();

export const getCardNumberType = (cardNumber: string): CardNumberType => {
  const sanitizedNumber = cardNumber.replace(/\D/g, "");

  const patterns: { type: CardNumberType; regex: RegExp }[] = [
    { type: "visa", regex: /^4[0-9]{12}(?:[0-9]{3})?$/ }, // VISA: Starts with 4, 13 or 16 digits
    { type: "mastercard", regex: /^5[1-5][0-9]{14}$/ }, // MasterCard: Starts with 51-55, 16 digits
    { type: "amex", regex: /^3[47][0-9]{13}$/ }, // Amex: Starts with 34 or 37, 15 digits
    { type: "discover", regex: /^6(?:011|5[0-9]{2})[0-9]{12}$/ }, // Discover: Starts with 6011 or 65, 16 digits
  ];

  for (const pattern of patterns) {
    if (pattern.regex.test(sanitizedNumber)) {
      return pattern.type;
    }
  }

  return "other";
};

export const formatMonthYear = (value: string) =>
  value.replace(/\D/g, "").replace(/(\d{2})(\d{1,2})/, "$1/$2");

export const isValidExpirationDate = (expirationDate: string) => {
  const expirationRegex = /^(0[1-9]|1[0-2])\/(2[3-9]|3[0-9]|40)$/; // Permite MM/YY desde 23 hasta 40
  if (!expirationRegex.test(expirationDate)) {
    return false;
  }

  const [month, year] = expirationDate.split("/").map(Number);
  const currentYear = new Date().getFullYear() % 100; // Últimos dos dígitos del año actual
  const currentMonth = new Date().getMonth() + 1;

  // Verifica si está en el pasado
  if (year < currentYear || (year === currentYear && month < currentMonth)) {
    return false;
  }

  return true;
};

export const formatCvv = (value: string) => value.replace(/\D/g, "");
