/**
 * Pack three booleans into a single number (LSB-first order) representing emailEnabled, whatsappEnabled, googleCalendarEnabled
 */
export function packNotificationPreference(
  emailEnabled: boolean,
  whatsappEnabled: boolean,
  googleCalendarEnabled: boolean
): number {
  return (
    (+emailEnabled << 0) | // Bit 0 (1)
    (+whatsappEnabled << 1) | // Bit 1 (2)
    (+googleCalendarEnabled << 2) // Bit 2 (4)
  );
}

/**
 * Given a 3bits number, returns an array of three booleans representing [emailEnabled, whatsappEnabled, googleCalendarEnabled]
 */
export function unpackNotificationPreference(
  packed: number
): [boolean, boolean, boolean] {
  return [
    (packed & 1) !== 0, // Bit 0
    (packed & (1 << 1)) !== 0, // Bit 1
    (packed & (1 << 2)) !== 0, // Bit 2
  ];
}
