import React, { useCallback } from "react";
import { Tooltip } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { StylesEnum } from "@mapsy/shared";

import { CustomButton } from "./Button";
import { useNavigate } from "react-router-dom";

interface Props {
  patientId: string;
}
export const SeePatientsHistoryButton: React.FC<Props> = ({ patientId }) => {
  const nav = useNavigate();
  const handleSeePatientsHistory = useCallback(() => {
    nav(`${patientId}/history`);
  }, []);

  return (
    <Tooltip title={"Ver Historial"}>
      <div>
        <CustomButton
          customStyle={StylesEnum.primary}
          analyticsLabel="Ver Historial"
          onClick={handleSeePatientsHistory}
          sx={{
            padding: "12px",
            minWidth: "50px",
          }}
        >
          <HistoryIcon sx={{ fontSize: "20px" }} />
        </CustomButton>
      </div>
    </Tooltip>
  );
};
