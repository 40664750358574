import React, {
  FormEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box, Stack, Typography } from "@mui/material";

import {
  Appointment,
  COLORS,
  EndpointGenerator,
  InputType,
  PaymentStatus,
  StylesEnum,
} from "@mapsy/shared";
import { CustomButton } from "components/atoms/Button";
import { ModalLayout } from "layouts/ModalLayout";
import { InputField } from "components/atoms/InputField";
import { capitalizeName } from "utils/capitalize";
import moment from "moment";
import { DATE_LONG_FORMAT } from "constants/defaultUserValues";
import { useAxios } from "hooks/useAxios";
import { setToken } from "utils/setToken";
import { useAppSelector } from "hooks";
import { selectSessionState } from "features/session/session.slice";

interface Props {
  appointment: Appointment;
  onPaymentStatusUpdated: (app: Appointment, newPaymentStatus: PaymentStatus) => void;
}

export const ChangeAppPaymentStatus: React.FC<Props> = ({
  appointment,
  onPaymentStatusUpdated,
}) => {
  const { date, patient, paymentStatus } = appointment;
  const { token } = useAppSelector(selectSessionState);
  const { patchData, isLoading, errorMsg } = useAxios();
  const momentDate = useMemo(() => moment(date), [date]);
  const [openModal, setOpenModal] = useState(false);
  const [hasSucceeded, setHasSucceeded] = useState(false);
  const [newPaymentStatus, setNewPaymentStatus] = useState(paymentStatus);

  useEffect(() => {
    setNewPaymentStatus(
      PaymentStatus[paymentStatus] !== undefined
        ? paymentStatus
        : PaymentStatus.None
    );
  }, [paymentStatus]);

  const handleOpenChangePaymentStatusModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (hasSucceeded) {
      interval = setTimeout(() => {
        setHasSucceeded(false);
        handleClose();
      }, 500);
    }

    return () => clearTimeout(interval);
  }, [hasSucceeded]);

  const handleChange = useCallback((propertyName: string, value: any) => {
    setNewPaymentStatus(value);
  }, []);

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      const endpoint = EndpointGenerator.AppointmentAPI.updatePaymentStatus(
        appointment._id,
        { paymentStatus: newPaymentStatus }
      );
      const response = await patchData(endpoint, undefined, setToken(token));

      if (response) {
        setHasSucceeded(true);
        onPaymentStatusUpdated(appointment, newPaymentStatus);
      }
    },
    [appointment, newPaymentStatus, token]
  );

  const info: { title: ReactNode; content: ReactNode }[] = useMemo(
    () => [
      {
        title: `Cita con: `,
        content:
          patient?.firstName && patient.lastName
            ? capitalizeName([
                patient?.firstName,
                patient?.middleName,
                patient?.lastName,
              ])
            : "Paciente no encontrado",
      },
      {
        title: `Fecha: `,
        content: momentDate.format(DATE_LONG_FORMAT),
      },

      {
        title: `Hora: `,
        content: momentDate.format("HH:mm a"),
      },
    ],
    [momentDate, patient]
  );

  if (paymentStatus === PaymentStatus.Completed) {
    return <></>;
  }

  return (
    <>
      <ModalLayout
        isOpen={openModal}
        onClose={handleClose}
        maxWidthContainer="sm"
      >
        <form onSubmit={handleSubmit}>
          <Stack sx={{ alignItems: "center", gap: { md: 2, xs: 1 } }}>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h4" sx={{ color: COLORS.BLUE_1 }}>
                Estatus del pago
              </Typography>
            </Box>
            <Box>
              {info.map(({ title, content }, i) => (
                <Typography key={`info-${title}-${i}`}>
                  <Typography
                    component="span"
                    sx={{ color: COLORS.BLUE_1, fontWeight: 500 }}
                  >
                    {title}
                  </Typography>
                  {content}
                </Typography>
              ))}
              {errorMsg && (
                <Typography sx={{ color: COLORS.TEXT_RED }}>
                  {errorMsg}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                width: { md: "50%", xs: "100%" },
                mb: { md: 8, xs: 4 },
              }}
            >
              <InputField
                inputType={InputType.Select}
                propertyName="paymentStatus"
                menuItems={[
                  {
                    label: "Pago pendiente",
                    value: PaymentStatus.None,
                  },
                  {
                    label: "Pago realizado",
                    value: PaymentStatus.Completed,
                  },
                ]}
                label=""
                fullWidth
                autoFocus
                handleChange={handleChange}
                value={newPaymentStatus}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                mr: 3,
                my: 2,
              }}
            >
              <CustomButton
                customStyle={StylesEnum.primary}
                isLoading={isLoading}
                children="Aceptar"
                type="submit"
                hasSucceeded={hasSucceeded}
              />
            </Box>
          </Stack>
        </form>
      </ModalLayout>
      <Box sx={{ width: "100%" }}>
        <CustomButton
          customStyle={StylesEnum.primary}
          isLoading={isLoading}
          children="Cambiar Estatus de Pago"
          sx={{ padding: 1, minWidth: "auto" }}
          onClick={handleOpenChangePaymentStatusModal}
        />
      </Box>
    </>
  );
};
