import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "hooks/useQuery";
import { Grid, CircularProgress, Typography } from "@mui/material";
import { TitledLayout } from "layouts/TitledLayout";
import { useAuth } from "hooks/useAuthAPI";
import { InviteValidationParams, TokenType, UserType } from "@mapsy/shared";
import { CustomLink } from "components/atoms/Link";

const START_COUNTER = 5;
const InviteEmail = () => {
  const nav = useNavigate();
  const query = useQuery();
  const email = query.get("email");
  const userType = query.get("userType");
  const tokenType = query.get("tokenType");
  const therapistId = query.get("therapistId");
  const { inviteValidation, errorMsg } = useAuth();
  const { token } = useParams(); //Token used to validate email
  const [countdown, setCountdown] = useState(START_COUNTER);
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  const [redirectUrl, setRedirectUrl] = useState("");

  useEffect(() => {
    return () => {
      if (timerId) {
        clearInterval(timerId)
      }
    }
  }, [timerId]);
  
  const validateToken = useCallback(async (params: InviteValidationParams, signal: AbortSignal) => {
    const response = await inviteValidation(params, signal);
    if (response?.status) {
      sessionStorage.setItem("signup-invite", JSON.stringify(params))
      setRedirectUrl(`/users/signup?token=${params.token}&type=${UserType[params.userType]?.toLowerCase()}&therapistId=${params.therapistId}`);

      let count = START_COUNTER
      const id = setInterval(() => {
        if (count === 0) {
          clearInterval(id)
        }

        setCountdown(count--);
      }, 1000);
      setTimerId(id);
    }
  }, []);

  const validParams = useMemo(() => {
    if (!email || !userType || !token || !TokenType[tokenType] || !therapistId) {
      return false;
    }

    return true
  }, [email, userType, token, tokenType, therapistId]);

  useEffect(() => {
    if (!validParams || !token) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    validateToken({ email, token, userType, tokenType, therapistId  }, signal)

    return () => {
      controller.abort()
    }
  }, [validParams, timerId]);

  useEffect(() => {
    if (countdown > 0 || !redirectUrl) {
      return
    }

    nav(redirectUrl, { replace: true })
  }, [countdown, redirectUrl, errorMsg])

  if (!validParams) {
    return (
      <TitledLayout title="¡Parece que la URL está rota!">
        <Grid item md={12}>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            Parece que no pudimos generar la solicitud por falta de información.
          </Typography>
        </Grid>
      </TitledLayout>
    );
  }

  if (errorMsg) {
    return (
      <TitledLayout title="No pudimos continuar">
        <Grid
          item
          md={8}
          sx={{ display: "flex", gap: 2, flexDirection: "column" }}
        >
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            {errorMsg}
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Si necesitas otro token, pide a tu terapeuta que te reenvíe la invitación.
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Si tienes alguna duda o problema, contacta a soporte al correo{" "}
            <CustomLink to="mailto:support@mapsy.com.mx" underline>
              support@mapsy.com.mx
            </CustomLink>{" "}
            o en la sección de{" "}
            <CustomLink to="/contact" underline target="_blank">
              contacto
            </CustomLink>
          </Typography>
        </Grid>
      </TitledLayout>
    );
  }

  return (
    <TitledLayout title="Espera a ser redireccionado...">
      <Grid item md={12} sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
        <CircularProgress />
      </Grid>
      <Grid item md={12} sx={{ textAlign: "center" }}>
        <Typography>
          Si no te redirige en {countdown} segundos, 
          haz <CustomLink variant="body1" to={redirectUrl} underline replace>Click aquí</CustomLink>
        </Typography>
      </Grid>
    </TitledLayout>
  );
};

export default InviteEmail;