import { Appointment } from "./appointment.interface";
import { PatientSlim } from "./patient.interface";
import { TherapistSlim } from "./therapist.interface";

export enum PaymentType {
  Appt,
  Subs,
}

// enum principalmente para ser usado
// en el schema de appointment
export enum PaymentStatus {
  None,
  Processing,
  Completed,
  Failed,
}
export interface CardValues {
  cardName: string;
  cardNumber: string;
  expirationDate: string;
  cvv: string;
}

export enum PaymentStatusSpanish {
  "Sin pago" = 0,
  "Procesando" = 1,
  "Pago realizado" = 2,
  "Pago fallido" = 3,
}

export interface Card {
  cardNumber: string;
  cardHolderName: string;
  expirationDate: string;
  brand: string;
  bank: string;
}

export interface PaymentInfo {
  id: string;
  card?: Card;
}

export interface PaymentSlim {
  _id?: string;
  amount: number;
  currency: string;
  date: number;
  method: string;
  authorization: string;
  cardNumber?: string;
  status: string;
  chargeId: string;
  relatedId: string;
}

export interface PaymentDto {
  patientId?: string;
  therapistId?: string;
  amount: number;
  currency: string;
  date: number;
  method: string;
  description: string;
  paymentType: PaymentType;
  relatedId: string;
  paymentInfo: PaymentInfo;
  authorization: string;
  status: string; // completed, rejected (no hay processing) es el estatus que nos regresa openpay
  chargeId: string;
  orderId: string;
}

export type PaymentWithReferrer = Omit<PaymentSlim, "cardNumber"> & {
  _id: string;
  orderId: string;
  patient: PatientSlim | null;
  therapist: TherapistSlim | null;
  appointment: Appointment | null;
} & (
    | {
        status: "completed";
        paymentInfo: {
          card: {
            bank: string;
            brand: string;
            cardHolderName: string;
            cardNumber: string;
            expirationDate: string;
          };
          id: string;
        };
      }
    | { status: "rejected"; paymentInfo: { id: string } }
  );

export enum PaymentsGroupBy {
  WEEK,
  MONTH,
  QUARTER,
  YEAR,
}

export interface PaymentsGrouped {
  _id: {
    year: number;
    month?: number;
    week?: number;
    quarter?: number;
  };
  total: number;
  count: number;
}
