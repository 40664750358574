import { Box, Container, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import logo from "assets/img/logotipo.webp";
import wa from "assets/svg/wa.svg";
import fb from "assets/svg/fb.svg";
import ig from "assets/svg/ig.svg";
import tiktok from "assets/svg/tiktok-icon.svg";
import COLORS from "constants/colors";
import { CustomLink } from "components/atoms/Link";

export const Footer = () => {
  return (
    <Box>
      <Box
        sx={{
          borderBottom: `1px solid ${COLORS.BLUE_2}}`,
          height: { md: "240px", xs: "120px" },
        }}
      ></Box>
      <Container maxWidth="lg" sx={{ pb: 6 }}>
        <Grid2
          container
          xs={12}
          direction={{ xs: "column", md: "row" }}
          sx={{ mt: 4, justifyContent: "space-between", alignItems: "center" }}
        >
          <Box
            component="img"
            src={logo}
            sx={{ width: { md: "162px", xs: "100px" } }}
            alt="mapsy-logo"
          ></Box>
          <Grid2
            container
            direction={{ xs: "column", sm: "row" }}
            sx={{
              gap: { md: "2rem", xs: "1.5rem" },
              justifyContent: "end",
              textAlign: { xs: "center", sm: "left" },
              flexGrow: 1,
              mt: { xs: 4, md: 0 },
            }}
          >
            <Box
              component="div"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                flexBasis: { md: "350px", xs: "auto" },
              }}
            >
              <Typography variant="h3">Legal</Typography>
              <Typography>
                <CustomLink
                  children="Términos y condiciones"
                  to="/terms-and-conditions"
                />
              </Typography>
              <Typography>
                <CustomLink
                  children="Aviso de privacidad"
                  to="/privacy-policy"
                />
              </Typography>
            </Box>

            <Box
              component="div"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="h3">Información</Typography>
              <Typography>
                <CustomLink children="Preguntas frecuentes" to="/faq" />
              </Typography>
              <Typography>
                <CustomLink children="Sobre nosotros" to="/about-us" />
              </Typography>
              <Typography>
                <CustomLink children="Contacto" to="/contact" />
              </Typography>
            </Box>
          </Grid2>
        </Grid2>
        <Grid2
          container
          xs={12}
          direction="row"
          sx={{
            mt: 6,
            justifyContent: { xs: "center", md: "start" },
            alignItems: "center",
            gap: { md: 8, xs: 4 },
          }}
        >
          <CustomLink
            to="https://wa.me/5543181573?text=Hola,%20Mapsy.%20Mi%20nombre%20es%20"
            target="_blank"
            analyticsLabel="Whatsapp Mapsy"
          >
            <img src={wa} alt="whatsapp" width={40.5} />
          </CustomLink>
          <CustomLink
            to="https://www.facebook.com/profile.php?id=61550819325192&mibextid=ZbWKwL"
            target="_blank"
            analyticsLabel="Facebook Mapsy"
          >
            <img src={fb} alt="facebook" width={40.5} />
          </CustomLink>
          <CustomLink
            to="https://www.instagram.com/mapsy.mx?igsh=ZmsxMjlmY3o0cXg3"
            target="_blank"
            analyticsLabel="Instagram Mapsy"
          >
            <img src={ig} alt="instagram" width={40.5} />
          </CustomLink>
          <CustomLink
            to="https://www.tiktok.com/@mapsy.mx?_t=8g7urGYC1bB&_r=1"
            target="_blank"
            analyticsLabel="Tiktok Mapsy"
          >
            <img src={tiktok} alt="tiktok" width={40.5} />
          </CustomLink>
        </Grid2>
      </Container>
    </Box>
  );
};
