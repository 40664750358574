import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import {
  COLORS,
  EndpointGenerator,
  OrderEnum,
  PatientSlim,
  StylesEnum,
} from "@mapsy/shared";
import { MyPatientsPcView } from "components/organisms/MyPatientsPcView";
import { useAxios } from "hooks/useAxios";
import { useSelector } from "react-redux";
import { selectSessionState } from "features/session/session.slice";
import { setToken } from "utils/setToken";
import { useParams } from "react-router-dom";
import { capitalizeName } from "utils/capitalize";
import { theme } from "theme";
import { MyPatientsMobileView } from "components/organisms/MyPatientsMobileView";
import { MyPatientsHeader } from "components/molecules/MyPatientsHeader";
import { CustomButton } from "components/atoms/Button";
import { AddPatientForTherapist } from "components/molecules/AddPatientForTherapist";

const PATIENTS_PER_PAGE = 20;

const MyPatients: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { patientId } = useParams(); //id of selected patient to list history
  const {
    isLoading: loading,
    errorMsg,
    getData,
  } = useAxios({ loadingDefault: true });
  const { token, profileInfo } = useSelector(selectSessionState);
  const [page, setPage] = useState(1);
  const [patients, setPatients] = useState<Record<number, PatientSlim[]>>({});
  const [open, setOpen] = useState(false);

  const flatPatients = useMemo(
    () => Object.values(patients).flat(),
    [patients]
  );
  const [totalPages, setTotalPages] = useState(1);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [currentPatientName, setCurrentPatientName] = useState("");

  const fetchPatients = useCallback(
    async (
      id: string,
      page: number,
      signal: AbortSignal,
      token: string,
      resetPatients?: boolean
    ) => {
      const endpoint =
        EndpointGenerator.TherapistAPI.getAllPatientsByTherapistId(id, {
          order: OrderEnum.ASC,
          orderBy: "firstName",
          limit: PATIENTS_PER_PAGE,
          page,
        });
      const response = await getData(endpoint, { signal, ...setToken(token) });

      if (!response?.results?.length) {
        return;
      }
      const { results, totalPages } = response;
      setTotalPages(totalPages);

      if (resetPatients) {
        setPage(1);
        setPatients({ 1: results });
        return;
      }
      setPatients((_patients) => ({ ..._patients, [page]: results }));
    },
    []
  );

  const handleNextPage = useCallback(() => {
    if (totalPages === page) {
      return;
    }
    setPage(page + 1);
  }, [page, totalPages]);

  const handleNewPatientAdded = useCallback(() => {
    if (!token || !profileInfo?.id) {
      return;
    }
    const controller = new AbortController();
    const { signal } = controller;

    fetchPatients(profileInfo.id, 1, signal, token, true);
  }, [profileInfo, page, token]);

  useEffect(() => {
    if (!token || !profileInfo?.id) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    fetchPatients(profileInfo.id, page, signal, token);
    return () => {
      controller.abort();
    };
  }, [page, token, profileInfo]);

  useEffect(() => {
    if (!patientId) {
      return;
    }
    const foundPatient = flatPatients.find(({ _id }) => _id === patientId);
    if (!foundPatient) {
      return;
    }
    setCurrentPatientName(
      capitalizeName([
        foundPatient.firstName,
        foundPatient.middleName,
        foundPatient.lastName,
      ])
    );
  }, [flatPatients, patientId]);

  return (
    <Container
      sx={{
        px: { xs: 1.5, md: 3 },
        py: { xs: 2, md: 3 },
        position: "relative",
      }}
    >
      {open && (
        <AddPatientForTherapist
          onNewPatientAdded={handleNewPatientAdded}
          onClose={() => setOpen(false)}
          open={open}
        />
      )}
      <Grid container>
        <Grid
          item
          xs={12}
          sm={9}
          md={10}
          sx={{
            p: 1,
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            flexDirection: "column",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          <MyPatientsHeader
            showAllPatientsHeader={!patientId}
            patientName={currentPatientName}
          />
        </Grid>
        <Grid
          item
          sx={{
            borderBottom: `solid 3px ${COLORS.BLUE_1}`,
            my: 2,
          }}
          md={6}
          xs={false}
        ></Grid>
        {!patientId && (
          <>
            <Grid
              item
              xs={12}
              sx={{
                my: { md: 3, xs: 1 },
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              id="my-patients-container"
            >
              <CustomButton
                customStyle={StylesEnum.primary}
                children={"Agregar Paciente"}
                disableRipple={true}
                onClick={() => setOpen(true)}
                sx={{
                  borderRadius: "14px",
                  mb: 2,
                  maxWidth: "fit-content",
                  alignSelf: "flex-end",
                }}
              />
              {isMobile ? (
                <MyPatientsMobileView
                  patients={flatPatients}
                  onNextPage={handleNextPage}
                  isLoading={loading}
                />
              ) : (
                <MyPatientsPcView
                  patients={flatPatients}
                  onNextPage={handleNextPage}
                  isLoading={loading}
                />
              )}
            </Grid>
            {errorMsg && (
              <Grid
                item
                xs={12}
                sx={{ my: { md: 3, xs: 1 }, textAlign: "center" }}
              >
                <Typography>{errorMsg}</Typography>
              </Grid>
            )}
          </>
        )}
        {children}
      </Grid>
    </Container>
  );
};

export default MyPatients;
