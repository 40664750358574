export const regexLibrary: { [inputName: string]: RegExp } = {
  name: /^[^\d]+$/,
  firstName: /^[^\d]+$/,
  middleName: /^[^\d]*$/,
  lastName: /^[^\d]+$/,
  "phone.number": /^[0-9]{10}$/,
  phoneNumber: /^[0-9]{10}$/,
  professionalId: /^[^\s\W]+$/,
  major: /^[^\d]+$/,
  position: /^[^\d]+$/,
  reason: /^[^\d]+$/,
  cp: /(^\d{5})(-\d{4})?$/,
  year: /[0-9]{4}/,
  startYear: /[0-9]{4}/,
  endYear: /[0-9]{4}/,
  price: /[0-9]{2,6}/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,40}))$/,
  password: /^(?=\d*)(?=[!@#$%^&*]*)(?=[a-z]*)(?=[A-Z]*).{8,16}$/,
  currentPassword: /^(?=\d*)(?=[!@#$%^&*]*)(?=[a-z]*)(?=[A-Z]*).{8,16}$/,
  newPassword: /^(?=\d*)(?=[!@#$%^&*]*)(?=[a-z]*)(?=[A-Z]*).{8,16}$/,
  default: /\w*/,
  cardName: /^[^\d]+$/,
  cvv: /\d{3,4}/,
};
