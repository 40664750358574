import { Moment } from "moment";
import { PipelineStage } from "mongoose";

import { ErrorMsg } from "../enum/errorMsg.enum";
import { ProvidedService } from "../interfaces";
import { SocketEventEnum } from "../enum/socket";
import {
  Gender,
  OrderEnum,
  SocialMediaReferrer,
  Subscription,
  TokenType,
  UserType,
} from "../enum/shared.enum";

type Order = "asc" | "desc";

export interface CommonUserInfo {
  email: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  password: string;
  gender: Gender;
  phone: PhoneNumber;
  birthDate: number;
  timezone?: string;
  accountStatus?: Partial<AccountStatus>;
}
export interface PhoneNumber {
  dial_code: string;
  number: string;
  country: string;
}

export interface MongoErrors {
  message: string;
  name: string;
  _message: string;
  errors: {
    [key: string]: {
      name: string;
      message: string;
      properties: {
        message: string;
        type: string;
        path: string;
      };
    };
  }[];
}

export interface ResponseError {
  path: string;
  timestamp: string;
  error: {
    message: string;
    statusCode: number;
  };
}

export interface UserSession {
  id: string;
  email: string;
  type: UserType;
  firstName: string;
  lastName: string;
}

export interface SocketEvent {
  patientId: string;
  therapistId: string;
  eventType: SocketEventEnum;
}

export interface SignInInput {
  email: string;
  password: string;
  userType: UserType;
}

export interface GoogleReqUserInfo {
  email: string;
  firstName: string;
  lastName: string;
  picture: string;
  language: string;
  accessToken: string;
  refreshToken: string;
  isEmailVerified: boolean;
}

export interface GoogleGetProfile {
  sub: string;
  email: string;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  email_verified: boolean;
  locale: string;
}

export enum RegistrationAction {
  SignIn,
  SignUp,
}

export interface GetAllPagedParams {
  page: number;
  size: number;
  orderBy: string;
  order: OrderEnum;
  match?: PipelineStage.FacetPipelineStage;
  stages?: PipelineStage.FacetPipelineStage[];
  overrideSorting?: Record<string, 1 | -1>;
}

export interface GetAllParams {
  orderBy: string;
  order: OrderEnum;
  match: PipelineStage | undefined;
  remainingStages: PipelineStage[] | undefined;
  propToRegex?: string;
  regex?: string;
}

export interface GetAllPagedResult<T = any> {
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  results: T[];
}

export interface Timestamps {
  createdAt: string;
  updatedAt: string;
}

export interface AccountStatus {
  isActive: boolean;
  hasProfilePic: boolean;
  emailVerified: boolean;
  phoneVerified: boolean;
  professionalIdVerified?: boolean;
  registrationComplete: boolean;
  subscription: Subscription;
  accountReminders?: number;
}

export interface NotificationPreferences {
  oneDayBeforeAtNoon: number;
  oneHourBefore: number;
}

export interface NotificationPreferencesBySource {
  oneDayBeforeAtNoon: NotificationSource;
  oneHourBefore: NotificationSource;
}

export interface NotificationSource {
  email: boolean;
  whatsapp: boolean;
  googleCalendar: boolean;
}

export interface UnpackedAccountReminders {
  completeProfileCounter: number;
  uploadMissingProfilePic: number;
  validateEmailCounter: number;
  validateProfessionalIdCounter: number;
}

export interface ImageURL {
  imageUrl?: string; //set by frontend
}

export interface UpdateUser<T> {
  _id: string;
  fieldsToUpdate: Partial<T> | { [key: string]: any };
}

export interface ProfileValidatedInfo {
  id: string;
  email: string;
  type: UserType;
  firstName: string;
  middleName: string;
  lastName: string;
  accountStatus: AccountStatus;
  googleTokens: { access_token: string; refresh_token: string } | null;
}

export interface ProfileInfo extends ProfileValidatedInfo {
  iat: number;
  exp: number;
}

export interface GoogleProfileInfo {
  login: {
    access_token: string;
  };
  data: {
    sub: string;
    email: string;
    name: string;
    given_name: string;
    family_name: string;
    picture: string;
    verified_email: boolean;
    locale: string;
  };
}

export interface GoogleRedirectionAction {
  userType: UserType;
  action: RegistrationAction;
}

export interface GoogleProfileInfoParams extends GoogleRedirectionAction {
  access_token: string;
  refresh_token: string;
}

export interface ErrorMsgConfig {
  [statusCode: number]: ErrorMsg;
}

export type GetAllQuery<T> = {
  order?: OrderEnum;
  orderBy?: keyof T | string;
  page?: number;
  limit?: number;
};

export type GetAllWithTopicId<T> = GetAllQuery<T> & { topicId?: string } & {
  therapistId?: string;
};

export interface SortList<T = any> {
  list: T[];
  prop: keyof T;
  order: OrderEnum;
}

export type ErrorsObj = { [inputName: string]: ErrorMsg | string };

export interface SelectedPreconfirmationTime {
  day: Moment;
  hour: Moment;
  service: Partial<ProvidedService>;
}

export interface ParsedSelectedPreconfirmationTime {
  hour: number;
  service: Partial<ProvidedService>;
}

export interface HoursByDayHash {
  [day: string]: Array<number>;
}

export interface UpdatePasswordPayload {
  id: string;
  email?: string;
  currentPassword?: string;
  newPassword: string;
}

export interface EmailValidationParams {
  token: string;
  email: string;
  userType: UserType;
  tokenType: TokenType;
}

export interface InviteValidationParams extends EmailValidationParams {
  therapistId: string;
}

export interface FindAvailableApppointmentsParams<T = undefined> {
  locationId: string;
  therapistId: string;
  day: number;
  therapist?: T;
  timezone: string;
}

export interface FilterBy<T = any> {
  filterBy?: keyof T | string;
  filterValue?: string | number | boolean;
}

export type Filters<T = any> = FilterBy<T>[];

export interface TrafficSource {
  id?: string | null;
  referrer: SocialMediaReferrer | string;
  fromAd: boolean;
}
