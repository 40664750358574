import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Box, Grid, List, ListItem, Typography } from "@mui/material";

import {
  ErrorMsg,
  StylesEnum,
  UserType,
  EndpointGenerator,
} from "@mapsy/shared";

import COLORS from "constants/colors";

import { CustomButton } from "./Button";
import { ModalLayout } from "layouts/ModalLayout";

import { selectSessionState } from "features/session/session.slice";
import { UploadInput } from "./form/UploadInput";
import { useAppSelector } from "hooks";
import { useAxios } from "hooks/useAxios";
import { setToken } from "utils/setToken";
import { AxiosProgressEvent, AxiosRequestConfig } from "axios";

interface Props {
  open: boolean;
  onClose: (url: string | null) => void;
  isTherapist: boolean;
}

const PREVIEW_SUFIX = "_preview";

export const ChangeProfilePicModal: React.FC<Props> = ({
  open,
  onClose,
  isTherapist,
}) => {
  const { token, profileInfo } = useAppSelector(selectSessionState);
  const {
    patchData: updateUser,
    isLoading: loading,
    errorMsg: profileUpdataError,
  } = useAxios();
  const { postData: upload, errorMsg: uploadError } = useAxios();
  const { deleteData: deletePic } = useAxios();
  const [previewImgUrl, setPreviewImgUrl] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>();
  const [modalOpen, setModalOpen] = useState(open);
  const [hasSuccedded, setHasSuccedded] = useState(false);

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  const clear = useCallback(
    (url: string | null) => {
      setModalOpen(false);
      setPreviewImgUrl(null);
      setFormData(undefined);
      setHasSuccedded(false);
      if (previewImgUrl) {
        deletePreviewPic(previewImgUrl);
      }
      onClose(url);
    },
    [previewImgUrl]
  );

  const updateProfile = useCallback(async () => {
    if (!profileInfo || !previewImgUrl || !formData) {
      return;
    }

    const { type, id } = profileInfo;

    const url =
      type === UserType.Therapist
        ? EndpointGenerator.TherapistAPI.urlById(id)
        : EndpointGenerator.PatientAPI.urlById(id);
    const config = setToken(token);

    const realImgUrl = previewImgUrl.slice(0, -PREVIEW_SUFIX.length);
    const fileUploadResponse = await uploadPic(realImgUrl, formData, {});
    const response = await updateUser(
      url,
      { "accountStatus.hasProfilePic": true },
      config
    );

    if (!response || !fileUploadResponse) {
      return;
    }

    setHasSuccedded(true);
    clear(realImgUrl);
  }, [profileInfo, previewImgUrl, formData]);

  const uploadPreviewPic = useCallback(
    async (file: File, setProgress: (arg: number) => void) => {
      if (!file) return;

      const formData = new FormData();
      formData.append("file", file);
      setFormData(formData);

      const filename = `profile_pic_${profileInfo?.id}${PREVIEW_SUFIX}`;
      const url = EndpointGenerator.FileAPI.filename(filename);
      const config: AxiosRequestConfig<any> = {
        onUploadProgress: ({ loaded, total }: AxiosProgressEvent) => {
          const percentage = Math.round((loaded * 100) / (total || file.size));
          setProgress(percentage);
        },
      };

      const response = await uploadPic(url, formData, config);

      if (!response) {
        return;
      }

      setPreviewImgUrl(url);
    },
    [profileInfo?.id]
  );

  const uploadPic = useCallback(
    async (
      url: string,
      formData: FormData,
      config: AxiosRequestConfig<any>
    ) => {
      const response = await upload(
        url,
        formData,
        {
          ...config,
          ...setToken(token),
        },
        {
          400: ErrorMsg.FileNotUpload,
        }
      );
      if (!response) {
        return;
      }
      return response;
    },
    [setToken, token, upload]
  );

  const deletePreviewPic = useCallback(
    async (url: string) => {
      await deletePic(url, setToken(token));
    },
    [token]
  );

  return (
    <ModalLayout
      isOpen={modalOpen}
      onClose={() => {
        if (loading) {
          return;
        }
        // validar que no haya nada in progress
        // y si cierra y ya ha subido su foto de perfil
        // actualizar el perfil, darle cargar y luego cerrar
        clear(null);
      }}
      showLoadingOverlay={loading}
      maxWidthContainer="sm"
    >
      <Grid container sx={{ padding: 2, gap: 3 }}>
        {_.isNull(previewImgUrl) ? (
          <>
            <Grid item xs={12} className="profile-pic-title">
              <Typography
                variant="h4"
                sx={{
                  color: COLORS.BLUE_1,
                  fontWeight: 500,
                }}
              >
                Especificaciones de foto de perfil
              </Typography>
            </Grid>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexFlow: "row wrap",
                flexBasis: "100%",
                justifyContent: "center",
              }}
              className="profile-pic-content"
            >
              <Box>
                {isTherapist ? (
                  <Typography variant="body2">
                    Queremos asegurarnos de que tu foto de perfil refleje tu
                    profesionalismo. Por favor, considera estos detalles:
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    Si eres paciente en Mapsy, no es necesario que subas una
                    foto de perfil. En caso de que lo desees hacer, considera
                    estos detalles:
                  </Typography>
                )}
                <List
                  sx={{
                    "& li": {
                      listStyle: "disc",
                      listStylePosition: "inside !important",
                      display: "list-item",
                      pt: 0.25,
                      pb: 0,
                    },
                  }}
                >
                  <ListItem>Fondo Blanco</ListItem>
                  <ListItem>
                    Estar tomada de frente, con la cara descubierta y los ojos
                    abiertos
                  </ListItem>
                  <ListItem>Preferible con vestimenta formal</ListItem>
                </List>
              </Box>
              <Box sx={{ display: "flex", pt: 2.5, width: "100%" }}>
                <UploadInput
                  allowedFiles={["image/*"]}
                  maxSize={2 ** 20 * 5} // 10MB
                  action={uploadPreviewPic}
                  loading={loading}
                />
              </Box>
              {uploadError && <Typography>{uploadError}</Typography>}
              {profileUpdataError && (
                <Typography>{ErrorMsg.ProfilePicStatusNotUpdated}</Typography>
              )}
            </Box>
          </>
        ) : (
          <>
            <Grid
              item
              xs={12}
              className="profile-pic-title"
              sx={{ textAlign: "center" }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: COLORS.BLUE_1,
                  fontWeight: 500,
                }}
              >
                ¡Foto de perfil actualizada!
              </Typography>
            </Grid>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexFlow: "row wrap",
                flexBasis: "100%",
                justifyContent: "center",
              }}
              className="profile-pic-content"
            >
              <Box
                sx={{
                  display: "flex",
                  width: "250px",
                  justifyContent: "center",
                  borderRadius: "20px",
                  border: "solid 1px",
                  p: { md: 4, xs: 2 },
                  "& img": {
                    aspectRatio: "4 / 5",
                    objectFit: "cover",
                    width: "100%",
                    borderRadius: "40px",
                  },
                }}
              >
                <img src={previewImgUrl} />
              </Box>
            </Box>
            <Grid
              className="profile-pic-footer"
              item
              xs={12}
              sx={{
                mt: 3,
                display: "flex",
                justifyContent: "space-evenly",
                gap: 1,
              }}
            >
              <CustomButton
                customStyle={StylesEnum.primary}
                sx={{ px: 1 }}
                type="submit"
                isLoading={loading}
                onClick={updateProfile}
                hasSucceeded={hasSuccedded}
              >
                Guardar
              </CustomButton>

              <CustomButton
                customStyle={StylesEnum.secondary}
                sx={{
                  borderRadius: "14px",
                  py: 1.5,
                  px: 2,
                }}
                disabled={loading}
                onClick={() => setPreviewImgUrl(null)}
              >
                Elegir otra foto
              </CustomButton>
            </Grid>
          </>
        )}
      </Grid>
    </ModalLayout>
  );
};
