import { Action } from "@mapsy/shared";
import { Typography, TypographyPropsVariantOverrides } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { OverridableStringUnion } from '@mui/types';
import COMPONENTS from "constants/componentNames";
import { useAnalytics } from "hooks/useAnalytics";
import { ReactNode, useCallback } from "react";
import { Link, LinkProps, To, useLocation } from "react-router-dom";

interface Props extends LinkProps, React.RefAttributes<HTMLAnchorElement> {
  children: ReactNode;
  to: To;
  underline?: boolean;
  hoverEffect?: boolean;
  style?: React.CSSProperties | undefined;
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
  analyticsLabel?: string;
}

export const CustomLink: React.FC<Props> = ({
  children,
  to,
  underline = false,
  hoverEffect = true,
  style,
  variant= "body2",
  analyticsLabel,
  ...restProps
}) => {
  const { pathname } = useLocation();
  const { createAnalytic } = useAnalytics();

  const handleClick = useCallback(() => {
    createAnalytic({
      action: Action.CLICK,
      componentName: COMPONENTS.CUSTOM_LINK,
      data: {
        to,
        children: analyticsLabel || children?.toString(),
        pathname,
      },
    });
  }, [children, to]);

  return (
    <Link
      onClick={handleClick}
      to={to}
      className={hoverEffect ? "text-hover" : undefined}
      style={{
        textDecoration: underline ? "underline" : "none",
        ...style,
      }}
      {...restProps}
    >
      <Typography variant={variant} component="span">{children}</Typography>
    </Link>
  );
};
