import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";

import { getTherapists, selectReasonState } from "features/reason/reasonSice";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  LoadingEnum,
  Modality,
  OrderEnum,
  GetAllTherapists,
  StylesEnum,
  COLORS,
  Action,
} from "@mapsy/shared";
import { FilteredTherapistsList } from "../molecules/FilteredTherapistsList";
import { TherapistsFilters } from "../molecules/TherapistsFilters";
import { SuggestedPsychologistsPageProps } from "pages/SuggestedPsychologists";
import { EmptyTherapistList } from "./EmptyTherapistList";
import { DEFAULT_PARAMS } from "constants/defaultUserValues";

import filters from "assets/svg/filters.svg";
import { CustomLink } from "components/atoms/Link";
import { CustomButton } from "components/atoms/Button";
import { ModalLayout } from "layouts/ModalLayout";
import _ from "lodash";
import { useAnalytics } from "hooks/useAnalytics";
import COMPONENTS from "constants/componentNames";

export const DEFAULT_PRICE_RANGE = [0, 2000]; //@TODO estimate max price from therapists data. Eventually we normalize to the same currency (i.e. USD to MXN)

interface Props extends SuggestedPsychologistsPageProps {}

export const TherapistsList: React.FC<Props> = ({ showAllTherapists }) => {
  const dispatch = useAppDispatch();
  const {
    consultingActions: { predictedTopicId },
    therapistsLoading,
    therapistsList,
    therapistsPagination: { noTherapistForTopic },
  } = useAppSelector(selectReasonState);
  const { createAnalytic } = useAnalytics();

  const [showFilters, setShowFilters] = useState(false);

  const defaultGetTherapistParams = useMemo(
    () => ({
      ...DEFAULT_PARAMS,
      topicId: predictedTopicId,
      getAllTherapists: showAllTherapists,
    }),
    [predictedTopicId, showAllTherapists]
  );
  const [getTherapistParams, setGetTherapistParams] =
    useState<GetAllTherapists>(defaultGetTherapistParams);

  useEffect(() => {
    dispatch(getTherapists(defaultGetTherapistParams));
  }, [showAllTherapists]);

  const therapists = useMemo(
    () => Object.values(therapistsList).flat(),
    [therapistsList]
  );

  const handleClearTherapistsFilters = useCallback(() => {
    setShowFilters(false);

    setGetTherapistParams(defaultGetTherapistParams);
    dispatch(getTherapists(defaultGetTherapistParams));
  }, [defaultGetTherapistParams]);

  const handleFilterTherapists = useCallback(
    (args: Omit<GetAllTherapists, "getAllTherapists" | "limit">) => {
      const newParams = { ...defaultGetTherapistParams, ...args };
      setGetTherapistParams(newParams);
      dispatch(getTherapists(newParams));

      createAnalytic({
        action: Action.SUBMIT,
        componentName: COMPONENTS.THERAPISTS_LIST,
        data: newParams,
      });

      setShowFilters(false);
    },
    [defaultGetTherapistParams]
  );

  if (therapistsLoading === LoadingEnum.failed) {
    return (
      <Typography>
        Lo sentimos, hubo un error. Por favor contacta a soporte técnico.
      </Typography>
    );
  }

  if (
    !therapists.length &&
    therapistsLoading === LoadingEnum.succeeded &&
    !showAllTherapists &&
    noTherapistForTopic
  ) {
    return <EmptyTherapistList />;
  }

  return (
    <>
      <ModalLayout
        isOpen={showFilters}
        maxWidthContainer="xs"
        onClose={() => setShowFilters(false)}
      >
        <Box>
          <TherapistsFilters
            onClearFilters={handleClearTherapistsFilters}
            onFilterTherapists={handleFilterTherapists}
          />
        </Box>
      </ModalLayout>

      <Box
        sx={{
          display: "flex",
          gap: "1.5rem",
          flexDirection: "column",
        }}
      >
        <Grid container>
          <Grid item xs={8} md={6}>
            {showAllTherapists ? (
              <Box>
                <Typography variant="body2">
                  Estás viendo la lista de todos los terapeutas registrados en
                  mapsy.
                </Typography>
                <Typography variant="body2">
                  También puedes{" "}
                  <CustomLink to={"/reason"} underline>
                    buscar por Inteligencia Artificial
                  </CustomLink>
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography variant="body2">
                  A continuación se te muestra una lista con los psicólogos
                  especializados en tu motivo de consulta.
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={4}
            md={6}
            sx={{ p: 1, display: "flex", justifyContent: "center" }}
          >
            <Box>
              <Tooltip
                title={showFilters ? "Ocultar filtros" : "Mostrar filtros"}
              >
                <CustomButton
                  customStyle={StylesEnum.secondary}
                  onClick={() => setShowFilters(!showFilters)}
                  sx={{
                    display: "flex",
                    gap: { md: 1, xs: 0.5 },
                    borderRadius: { xs: "20px", md: "10px" },
                  }}
                  analyticsLabel="Ver Filtros"
                >
                  <Box
                    component="img"
                    src={filters}
                    sx={{
                      width: { md: "18px", xs: "14px" },
                      cursor: "pointer",
                      color: COLORS.PURE_WHITE,
                    }}
                  />
                  <Typography variant="caption">Filtros</Typography>
                </CustomButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
        <FilteredTherapistsList
          getTherapistParams={getTherapistParams}
          showAllTherapists={showAllTherapists}
          therapists={therapists}
        />
      </Box>
    </>
  );
};
