export * from "./appointment.interface";
export * from "./auth.interface";
export * from "./catalogs.interface";
export * from "./comments.interface";
export * from "./email.interface";
export * from "./patient.interface";
export * from "./prompt.interface";
export * from "./shared.inteface";
export * from "./therapist.interface";
export * from "./topic.interface";
export * from "./token.interface";
export * from "./notification.interface";
export * from "./schema.interface";
export * from "./zipcode.interface";
export * from "./promotion.interface";
export * from "./table.interface";
export * from "./payment.interface";
export * from "./invoice.interface";
