import { APINames } from "../../enum/shared.enum";
import BaseAPI from "./BaseEndpointAPI";

class PatientsAPI extends BaseAPI {
  constructor() {
    super(APINames.PATIENT);
  }

  changePasswordById(id: string) {
    return `${this.baseURL}/change-password/${id}`;
  }

  createAccount(therapisId: string, fromInvite = false) {
    if (therapisId && fromInvite) {
      return `${this.baseURL}?fromInvite=true&therapistId=${therapisId}`;
    }

    return this.baseURL
  }
}

const api = new PatientsAPI();

export default api;
