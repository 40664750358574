import moment from "moment";
import React, { useEffect, useRef } from "react";
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";

import { COLORS, PatientSlim, UserType } from "@mapsy/shared";
import { capitalizeName } from "utils/capitalize";
import { SeePatientsHistoryButton } from "components/atoms/SeePatientHistoryButton";
import SendInviteButton from "./SendInviteButton";

interface Props {
  patients: PatientSlim[];
  onNextPage: () => void;
  isLoading: boolean;
}

export const MyPatientsMobileView: React.FC<Props> = ({
  patients,
  onNextPage,
  isLoading,
}) => {
  const observerTarget = useRef(null);

  useEffect(() => {
    const currentTarget = observerTarget.current;
    if (!currentTarget) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onNextPage();
        }
      },
      { threshold: 0 }
    );

    observer.observe(currentTarget);
    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [observerTarget, onNextPage]);

  if (isLoading) {
    return (
      <Box>
        <CircularProgress sx={{ color: COLORS.BLUE_1 }} />
      </Box>
    );
  }

  if (!patients.length) {
    return (
      <Box>
        <Typography>Aún no tienes pacientes</Typography>
      </Box>
    );
  }

  return (
    <Stack sx={{ rowGap: 2 }} id="my-patients-mobile">
      {patients.map(
        (
          {
            _id,
            firstName,
            middleName,
            lastName,
            email,
            phone,
            birthDate,
            imported,
          },
          index
        ) => (
          <Box
            sx={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              borderRadius: "20px",
              textAlign: "left",
              py: 2,
              px: 1.5,
            }}
            key={`patients-list-box-${_id}`}
            ref={index === patients.length - 1 ? observerTarget : null}
          >
            <Grid container>
              <Grid
                item
                xs={8}
                sx={{ display: "flex", flexDirection: "column", rowGap: 0.5 }}
              >
                {firstName && lastName ? (
                  <Typography>
                    {capitalizeName([firstName, middleName, lastName])}
                  </Typography>
                ) : (
                  <Typography variant="caption">
                    Pendiente por completar
                  </Typography>
                )}
                <Typography variant="caption" component="p">
                  Correo: {email}
                </Typography>
                {phone?.dial_code && phone.number ? (
                  <Typography variant="caption" component="p">
                    Número: ({phone.dial_code}) {phone.number}
                  </Typography>
                ) : (
                  <Typography variant="caption">
                    Pendiente por completar
                  </Typography>
                )}
                <Typography variant="caption" component="p">
                  Edad: {moment().diff(birthDate, "years")} años
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <SeePatientsHistoryButton patientId={_id} />
                {imported && (
                  <SendInviteButton
                    userEmail={email}
                    userId={_id}
                    userType={UserType.Patient}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        )
      )}
    </Stack>
  );
};
