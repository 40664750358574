import { useState, useRef, useEffect, useCallback } from "react";
import { getFilesFromDataTransferItems } from "datatransfer-files-promise";
import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";

import COLORS from "constants/colors";
import { CustomButton } from "../Button";
import { StylesEnum } from "@mapsy/shared";
import { CloudUpArrowIcon } from "icons/CloudUpArrow";
import CloseIcon from '@mui/icons-material/Close';
import { FileIcon } from "icons/FileIcon"

const formatFileSize = (size: number) => {
  if (size === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return `${parseFloat((size / Math.pow(k, i)).toFixed(2))}${sizes[i]}`;
};

type Fn = (...args: any) => Promise<void>;

export const UploadInput: React.FC<{
  allowedFiles: string[];
  maxSize?: number;
  action?: Fn;
  [key: string]: any;
  height?: any;
  valid?: boolean;
  errorMsg?: string | null;
}> = ({
  onChange,
  action,
  maxSize = Infinity,
  allowedFiles = ["image/*"],
  height = { xs: "fit-content", sm: "250px"},
  valid = null,
  errorMsg = "",
  ...props
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selected, setSelected] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [reading, setReading] = useState(props.loading ?? false);
  const [filename, setFilename] = useState("");
  const [size, setSize] = useState("");
  const [innerValid, setValid] = useState(valid ?? true);
  const [innerMsg, setErrorMsg] = useState(errorMsg);
  const [progress, setProgress] = useState(0);

  const stop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    setReading(props.loading ?? false);
  }, [props.loading]);



  const clear = useCallback(() => {
    setReading(false);
    setDragOver(false);
    setSelected(false);
    setFilename("");
    setSize("");
    onChange?.(null);
  }, [onChange]);

  const selectFile = useCallback((file: File) => {
    if (!file) {
      clear();
      return;
    }

    setFilename(file.name);
    setSize(formatFileSize(file.size));
    setSelected(true);

    if (onChange) {
      onChange(file);
      return;
    }

    action?.(file, setProgress);
  }, [action, onChange, clear]);

  const onDragOver = function (e: React.DragEvent<HTMLDivElement>) {
    e.dataTransfer.dropEffect = "move";
    stop(e);
    if (!e.dataTransfer || selected) return;

    setDragOver(true);
  };

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    stop(e);
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    stop(e);
    if (selected) {
      return;
    }
    setDragOver(false);
  };

  const fileTypeValidation = (files: File[]) => {
    if (!allowedFiles || !allowedFiles?.length) {
      return true;
    }

    return files.every((f) =>
      allowedFiles.some((type) => f.type.startsWith(type))
    );
  };

  const sizeValidation = (files: File[]) => {
    return !files.some((f) => f.size > (maxSize || Infinity));
  };

  const areFilesValid = useCallback(
    (files: File[] | FileList | null): boolean | undefined => {
      if (!files) {
        return;
      }

      const filesArray = Array.isArray(files) ? files : Array.from(files);

      if (filesArray.length > 1) {
        setErrorMsg(`Por favor selecciona sólo 1 archivo.`);
        return false;
      }

      if (!sizeValidation(filesArray)) {
        setErrorMsg(
          `El archivo es más grande que el límite permitido (${formatFileSize(maxSize)})`
        );
        return false;
      }

      if (!fileTypeValidation(filesArray)) {
        setErrorMsg(
          "El tipo de archivo no es permitido. Selecciona un archivo válido"
        );
        return false;
      }

      return true;
    },
    [maxSize, sizeValidation, setErrorMsg]
  );

  const onDrop = useCallback(async (e: React.DragEvent<HTMLDivElement>) => {
    stop(e);
    e.dataTransfer.dropEffect = "move";
    const dataTransferItems = e.dataTransfer.items;
    setReading(true);
    setDragOver(true);
    if (selected || dataTransferItems.length > 1) {
      e.dataTransfer.dropEffect = "none";
      clear();
      return;
    }
    try {
      const files = await getFilesFromDataTransferItems(dataTransferItems);
      setReading(false);

      if (!areFilesValid(files)) {
        setValid(false);
        clear();
        return;
      }

      setValid(true);
      setErrorMsg("");
      setDragOver(false);
      selectFile(files[0]);
    } catch (err) {
      setValid(false);
      setErrorMsg("Ha ocurrido un error al procesar el archivo.");
    }
  }, []);

  const removeFile = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setSelected(false);
    onChange?.(null);
  };

  const onOpenFile = (e: any) => {
    stop(e);

    if (!fileInputRef?.current) {
      return;
    }

    fileInputRef.current.click();
  };

  const onInputFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files || !areFilesValid(files)) {
      setValid(false);
      clear();
      return;
    }

    setValid(true);
    setErrorMsg("");
    setDragOver(false);
    selectFile(files[0]);
  };

  return (
    <Box
      className="upload-input"
      sx={{
        padding: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Box
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
        sx={{
          boxShadow: dragOver ? "0 0 20px rgba(100,100,100,0.3)" : "",
          display: "flex",
          border: innerValid
            ? `dotted ${COLORS.BLUE_1} 3px`
            : `dotted 3px ${COLORS.TEXT_RED}`,
          height,
          borderRadius: "14px",
        }}
        id="drag-container"
      >
        {selected ? (
          <Box
            className="file_selected"
            sx={{
              display: "flex",
              p: 1,
              position: "relative",
              borderRadius: "5px",
              flexBasis: "100%",
            }}
          >
            <Box
              className="file_selected_wrapper"
              sx={{
                mt: 0,
                position: "relative",
                color: "#38454E",
                display: "flex",
                flexFlow: {xs: "column", sm: "row wrap"},
                alignItems: "center",
                flexGrow: 1,
                gap: {xs: 1, sm: "5px"},
                justifyContent: "center",
              }}
            >
              <FileIcon />
              <CloseIcon 
                sx={{ 
                  position: "absolute", 
                  justifyContent: 'end', 
                  maxWidth: "fit-content", 
                  p: 0, 
                  m: 0,
                  right: {xs: "5%", sm: "calc(0% + 1rem)", md: "" },
                  top: { xs: "calc(50% - 0.65rem)", sm: "45%"  },
                  fontSize: {xs: "1.25rem"}
                }}
                onClick={clear}
                />
              <Box
                sx={{
                  flexGrow: 3,
                  textAlign: "left",
                  display: "flex",
                  flexFlow: "column nowrap",
                  justifyContent: "flex-start",
                  pl: 1,
                  maxWidth: "calc(75% - 5px)",
                }}
                className="assetInfo"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: {xs: "normal", sm: "space-between"},
                    flexGrow: {xs: 0, sm: 1},
                    width: "100%",
                    alignItems: "baseline",
                    flexWrap: {xs: "wrap", sm: "nowrap"},
                    gap: {xs: 1, sm: 0},
                  }}
                >
                  <Typography
                    sx={{ fontSize: {xs: "14px", sm: "10px"}, fontWeight: 600, lineHeight: 1 }}
                    variant="subtitle2"
                  >
                    {filename}
                  </Typography>
                  <Typography
                    sx={{ fontSize: {xs: "12px", sm: "10px"}, fontWeight: 600, lineHeight: 1, color: COLORS.DISABLED_GREY }}
                    variant="subtitle2"
                  >
                    {size}
                  </Typography>
                  
                </Box>
                {action && <LinearProgress
                  sx={{ color: COLORS.BLUE_2, borderRadius: "21px", mt: 0.5 }}
                  color="inherit"
                  variant="determinate"
                  value={progress}
                /> }
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              p: 2,
              width: "100%",
              boxSizing: "border-box",
              display: "flex",
              cursor: "default",
              justifyContent: "center",
            }}
            className="non_selected"
          >
            {reading ? (
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  gap: "15px",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  size="1.5rem"
                  thickness={4}
                  color="secondary"
                />
                <Typography variant="body2">Cargando...</Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  justifyContent: "center",
                  alignItems: "center",
                  rowGap: 1.5,
                  textAlign: "center"
                }}
              >
                <CloudUpArrowIcon sx={{ color: COLORS.BLUE_1, fontSize: 60 }}/>
                <Typography variant="body2">
                  Arrastra el archivo a esta área para cargarlo
                </Typography>
                <Typography variant="body2">o</Typography>
                <CustomButton
                  sx={{
                    padding: 1.5,
                    maxWidth: "190px",
                  }}
                  customStyle={StylesEnum.muted}
                  onClick={onOpenFile}
                >
                  Seleccionar archivo
                </CustomButton>
              </Box>
            )}
            <Box
              sx={{
                display: "inline-block",
                position: "relative",
                maxWidth: "100%",
                verticalAlign: "middle",
                // tomado de aqui
                // https://github.com/uikit/uikit/blob/af6f64c9647c962638d6ef1c57dbf8897b81c7df/src/scss/components/form.scss#L556
                '& > input[type="file"]': {
                  position: "absolute",
                  top: 0,
                  zIndex: 1,
                  width: "100%",
                  height: "100%",
                  left: 0,
                  WebkitAppearance: "none",
                  opacity: 0,
                  cursor: "pointer",
                  overflow: "hidden",
                },
              }}
            >
              <input
                ref={fileInputRef}
                type="file"
                onChange={onInputFileChange}
                accept={allowedFiles.join(",")}
              />
            </Box>
          </Box>
        )}
      </Box>
      {!innerValid ? (
        <Box
          sx={{
            textAlign: "left",
            mt: 1,
          }}
        >
          <Typography variant="caption" color={COLORS.TEXT_RED}>
            {innerMsg}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
