import React, { useCallback, useEffect, useState } from "react";

import { env } from "../env";

export const useOpenpay = () => {
  const [loaded, setLoaded] = useState(false);
  const [sandboxMode, setSandboxMode] = useState(true);

  useEffect(() => {
    // Openpay es una mamada y solo provee un CDN que no es un ES Module
    // Hay que importarlo asi
    // Podemos mover esto antes del nav() anterior
    const OpenPay = (window as any).OpenPay
    if (OpenPay) {
      if (!env.REACT_APP_OPENPAY_MERCHANT_ID || !env.REACT_APP_OPENPAY_MERCHANT_API_KEY
      ) {
        setLoaded(false);
        return;
      }

      OpenPay.setId(env.REACT_APP_OPENPAY_MERCHANT_ID);
      OpenPay.setApiKey(env.REACT_APP_OPENPAY_MERCHANT_API_KEY);
      OpenPay.setSandboxMode(
        env.REACT_APP_OPENPAY_SANDBOX_MODE === true ||
          env.REACT_APP_OPENPAY_SANDBOX_MODE === "true"
      );

      if(OpenPay.getSandboxMode()) {
        setSandboxMode(true);
      }

      setLoaded(true);
    }
  }, []);

  const deviceSetup = useCallback(() => {
    if (!loaded) {
      return null;
    }

    const OpenPay = (window as any).OpenPay

    return OpenPay.deviceData.setup();
  }, [loaded]);

  const createCardToken = useCallback((cardValues: any) => {
    if (!loaded) {
      return Promise.resolve("Openpay not loaded");
    }

    const OpenPay = (window as any).OpenPay
    return new Promise((resolve, reject) => {
      OpenPay.token.create({
        card_number: cardValues.cardNumber,
        holder_name: cardValues.cardName,
        expiration_year: cardValues.expYear,
        expiration_month: cardValues.expMonth,
        cvv2: cardValues.cvv,
        // TODO: agregar address al formulario
        // address:{
        //    city:"Querétaro",
        //    line3:"Queretaro",
        //    postal_code:"76900",
        //    line1:"Av 5 de Febrero",
        //    line2:"Roble 207",
        //    state:"Queretaro",
        //    country_code:"MX"
        // }
      }, (suc: any) => {
        resolve(suc);
      }, (err: any) => {
        reject(err)
      });
    })
  }, [loaded])


  const validateCard = useCallback((card: string) => {
    if (!loaded) return false;

    const OpenPay = (window as any).OpenPay

    return OpenPay.card.validateCardNumber(card); 
  }, [loaded]);

  const validateExp = useCallback((m: string, y: string) => {
    if (!loaded) return false;

    const OpenPay = (window as any).OpenPay

    return OpenPay.card.validateExpiry(m, y);
  }, [loaded]);

  const validateCvv = useCallback((cvv: string, card: string | null = null) => {
    if (!loaded) return false;

    const OpenPay = (window as any).OpenPay

    if (card) {
      return OpenPay.card.validateCVC(cvv, card);
    }

    return OpenPay.card.validateCVC(cvv);
    
  }, [loaded]);

  const getCardType = useCallback((card: string) => {
    if (!loaded) return false;

    const OpenPay = (window as any).OpenPay

    return OpenPay.card.cardType(card);
  }, [loaded]);

  return { loaded, sandboxMode, deviceSetup, createCardToken, getCardType, validateCvv, validateExp, validateCard };
};
