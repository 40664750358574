import { APINames, OrderEnum, PaymentsGroupBy, PaymentSlim } from "../..";

import BaseAPI from "./BaseEndpointAPI";

class PaymentsAPI extends BaseAPI {
  constructor() {
    super(APINames.PAYMENTS);
  }

  openpayCard() {
    return `${this.baseURL}/openpay/card`;
  }

  getAll(params: {
    orderBy: keyof PaymentSlim | string;
    order: OrderEnum;
    page: number;
    limit: number;
    patientId?: string;
    therapistId?: string;
  }) {
    const queryString = new URLSearchParams(
      params as Record<string, any>
    ).toString();
    return `${this.baseURL}?${queryString}`;
  }

  getInvoiceFromPayment(paymentId: string) {
    return `${this.baseURL}/${paymentId}/invoice`;
  }

  getPaymentsGrouped(params: { groupedBy: PaymentsGroupBy }) {
    const queryString = new URLSearchParams(
      params as Record<string, any>
    ).toString();
    return `${this.baseURL}/grouped?${queryString}`;
  }
}

const api = new PaymentsAPI();

export default api;
