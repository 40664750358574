import {
  CreatePatient,
  CreateTherapist,
  Currency,
  DefaultLocation,
  Gender,
  Modality,
  NotificationPreferences,
  OrderEnum,
  ProfessionalExperience,
  ProvidedService,
  ProvidedServiceTypes,
  SchoolGrade,
  SchoolGradeLevel,
  Theories,
  WorkDay,
  WorkSchedule,
} from "@mapsy/shared";
import { env } from "env";
import moment from "moment";
import momentTz from "moment-timezone";

export const DATE_LONG_FORMAT = "dddd DD [de] MMMM [del] YYYY";

export const MAX_LIMIT_THERAPISTS = env.REACT_APP_MAX_LIMIT_THERAPISTS || 10;

export const MAX_TOPICS_ID = 5;

export const defaultService: Partial<ProvidedService> = {
  serviceType: ProvidedServiceTypes.Individual,
  price: 500,
  currency: Currency.MXN,
};

export const defaultProfessionalExperience: ProfessionalExperience = {
  institution: "",
  startYear: 2018,
  endYear: 2020,
  position: "",
};

export const defaultSchoolGrade: SchoolGrade = {
  institution: "",
  major: "",
  year: 2020,
  level: SchoolGradeLevel.Lic,
};

export const defaultWorkday: WorkDay = {
  workable: false,
  endTime: moment("17", "HH").local().format(),
  startTime: moment("08", "HH").local().format(),
};

export const defaultWorkSchedule: WorkSchedule = {
  monday: { ...defaultWorkday },
  tuesday: { ...defaultWorkday },
  wednesday: { ...defaultWorkday },
  thursday: { ...defaultWorkday },
  friday: { ...defaultWorkday },
  saturday: { ...defaultWorkday },
  sunday: { ...defaultWorkday },
};

export const defaultLocation: (i: number) => DefaultLocation = (i) => ({
  city: "",
  community: "",
  country: "",
  cp: "",
  externalNo: "",
  internalNo: "",
  modality: Modality.Online,
  name: `Consultorio ${i}`,
  providedServices: [defaultService],
  state: "",
  street: "",
  workSchedule: defaultWorkSchedule,
});

export const defaultPhone = {
  dial_code: "+52",
  number: "",
  country: "Mexico",
};

export const defaultBirthDate = moment().subtract(18, "years").valueOf();
export const defaultMaxBirthDate = moment().subtract(10, "years"); //Should be Moment instead of valueOf

export const defaultTz = "America/Mexico_City";

export const defaultCommonValues = {
  email: "",
  firstName: "",
  password: "",
  lastName: "",
  phone: defaultPhone,
  birthDate: defaultBirthDate,
  timezone: momentTz.tz.guess(true) || defaultTz,
  gender: Gender.O,
};

export const defaultPatient: CreatePatient = defaultCommonValues;

export const defaultGrade: Partial<SchoolGrade> = {
  institution: "",
  year: undefined,
  major: "",
  level: SchoolGradeLevel.Lic,
};

export const defaultTherapist: CreateTherapist = {
  ...defaultCommonValues,
  professionalId: "",
  theory: Theories.Conductual,
  schoolGrades: [defaultGrade],
  professionalExperience: [defaultProfessionalExperience],
  credentials: "",
  password: "",
  topicsId: [],
  locations: [defaultLocation(1)],
};

export const DEFAULT_PARAMS = {
  limit: MAX_LIMIT_THERAPISTS,
  orderBy: "locations.providedServices.price",
  order: OrderEnum.DESC,
  page: 1,
  includeTopicSiblings: true
};

export const defaultNotificationPreferences: NotificationPreferences = {
  oneDayBeforeAtNoon: 7,
  oneHourBefore: 7
}