import { ReactNode, FormEvent, useMemo, useEffect } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { StylesEnum } from "@mapsy/shared";
import { CustomButton } from "components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { AlertInline } from "components/atoms/AlertInline";

interface Props {
  children: ReactNode;
  isBackButtonLoading?: boolean;
  hasSucceeded?: boolean;
  setHasSucceeded?: (arg: boolean) => void;
  errorMsg?: string | null;
}

export const ALERT_TIMER = 5000;
const MyProfileLayoutWrapper: React.FC<Props> = ({
  children,
  isBackButtonLoading,
  hasSucceeded,
  errorMsg,
  setHasSucceeded,
}) => {
  const nav = useNavigate();

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (hasSucceeded) {
      intervalId = setTimeout(() => {
        setHasSucceeded?.(false);
      }, ALERT_TIMER);
    }
    return () => clearTimeout(intervalId);
  }, [hasSucceeded]);

  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      <Grid container>
        {isBackButtonLoading && (
          <AlertInline
            open={isBackButtonLoading}
            msg="Guardando..."
          />
        )
        }
        {hasSucceeded && (
          <AlertInline
            open={hasSucceeded}
            msg="Información actualizada exitosamente."
          />
        )}
        
        {children}

        {isBackButtonLoading && (
          <AlertInline
            open={isBackButtonLoading}
            msg="Guardando..."
          />
        )
        }
        {hasSucceeded && (
          <AlertInline
            open={hasSucceeded}
            msg="Información actualizada exitosamente."
          />
        )}
        {errorMsg && (
          <Grid
            item
            xs={12}
            md={10}
            sx={{ justifyContent: "end", display: "flex" }}
          >
            <Typography>{errorMsg}</Typography>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={10}
          sx={{ justifyContent: "end", display: "flex", my: 3 }}
        >
          <CustomButton
            customStyle={StylesEnum.secondary}
            disableRipple={true}
            isLoading={isBackButtonLoading}
            onClick={() => nav(-1)}
            sx={{
              borderRadius: "14px",
              padding: 1,
            }}
          >
            Volver
          </CustomButton>
        </Grid>
      </Grid>
    </Container>
  );
};

interface MyProfileLayoutProps extends Props {
  isForm?: boolean;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
}

export const MyProfileLayout: React.FC<MyProfileLayoutProps> = ({
  children,
  isForm,
  onSubmit,
  ...restProps
}) => {
  if (isForm) {
    return (
      <form
        action=""
        method="patch"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit?.(e);
        }}
      >
        <MyProfileLayoutWrapper {...restProps}>
          {children}
        </MyProfileLayoutWrapper>
      </form>
    );
  }

  return (
    <MyProfileLayoutWrapper {...restProps}>{children}</MyProfileLayoutWrapper>
  );
};
