import { AppointmentStatus, COLORS } from "@mapsy/shared";
import { Typography } from "@mui/material";
import React from "react";
import { CustomLink } from "../Link";

interface Props {
  appointmentStatus: AppointmentStatus;
  patientId?: string;
  therapistId?: string;
}
export const AddReviewForTherapist: React.FC<Props> = ({
  appointmentStatus,
  patientId,
  therapistId,
  ...rest
}) => {
  if (
    appointmentStatus !== AppointmentStatus.Done ||
    !patientId ||
    !therapistId
  ) {
    return <></>;
  }

  return (
    <CustomLink
      to={`/patient/review?patientId=${patientId}&therapistId=${therapistId}`}
      underline
      analyticsLabel="Agrega una opinión del terapeuta"
    >
      <Typography
        sx={{ fontWeight: 500, color: COLORS.BLUE_1 }}
        variant="caption"
      >
        Agregar opinión
      </Typography>
    </CustomLink>
  );
};
