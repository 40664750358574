import _ from "lodash";
import React, { useEffect, useRef } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { COLORS, PatientSlim, TableColumns, UserType } from "@mapsy/shared";
import { capitalizeName } from "utils/capitalize";
import moment from "moment";
import SendInviteButton from "./SendInviteButton";
import { SeePatientsHistoryButton } from "components/atoms/SeePatientHistoryButton";

const columns: TableColumns<PatientSlim> = [
  {
    title: "Nombre",
    propName: "firstName",
    compute({ firstName, middleName, lastName }) {
      if (!firstName && !lastName) {
        return (
          <Typography variant="caption">Pendiente por completar</Typography>
        );
      }

      return capitalizeName([firstName, middleName, lastName]);
    },
  },
  {
    title: "Número",
    propName: "phone.number",
    compute({ phone }) {
      if (!phone) {
        return (
          <Typography variant="caption">Pendiente por completar</Typography>
        );
      }

      return `(${phone?.dial_code}) ${phone?.number}`;
    },
  },
  {
    title: "Correo",
    propName: "email",
  },
  {
    title: "Edad",
    propName: "birthDate",
    compute({ birthDate }) {
      return `${moment().diff(birthDate, "years")} años`;
    },
  },
  {
    title: "Acciones",
    propName: "appointments-history",
    compute({ _id, email, imported }) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: { md: 1, xs: 0.5 },
          }}
        >
          <SeePatientsHistoryButton patientId={_id} />
          {imported && (
            <SendInviteButton
              userEmail={email}
              userId={_id}
              userType={UserType.Patient}
            />
          )}
        </Box>
      );
    },
  },
];

const MyPatientsPcViewTable: React.FC<{
  patients: PatientSlim[];
  onNextPage: () => void;
}> = ({ patients, onNextPage }) => {
  const observerTarget = useRef(null);

  useEffect(() => {
    const currentTarget = observerTarget.current;
    if (!currentTarget) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onNextPage();
        }
      },
      { threshold: 0 }
    );

    observer.observe(currentTarget);
    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [observerTarget, onNextPage]);

  if (!patients.length) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={columns.length}>
            Aún no tienes pacientes
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {patients.map((patient, index) => (
        <TableRow
          key={`patients-table-body-row-${patient._id}`}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          {columns.map(({ propName, compute }) => (
            <TableCell
              sx={{ textAlign: "center" }}
              key={`patients-table-body-cell-${patient._id}-${propName}`}
              ref={index === patients.length - 1 ? observerTarget : null}
            >
              {compute?.(patient) || _.get(patient, propName, "")}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

interface Props {
  patients: PatientSlim[];
  onNextPage: () => void;
  isLoading: boolean;
}
export const MyPatientsPcView: React.FC<Props> = ({
  patients,
  onNextPage,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <Box>
        <CircularProgress sx={{ color: COLORS.BLUE_1 }} />
      </Box>
    );
  }

  return (
    <TableContainer
      component={Paper}
      sx={{ backgroundColor: "transparent", boxShadow: "none" }}
      id="table-pacientes-desktop"
    >
      <Table
        sx={{
          minWidth: 650,
          "& th, & td": {
            borderBottom: "3px solid rgba(2, 88, 123, 1)",
          },
        }}
        aria-label="my-patients-table"
      >
        <TableHead
          sx={{
            backgroundColor: "transparent",
          }}
        >
          <TableRow>
            {columns.map(({ propName, title }) => (
              <TableCell
                key={`table-header-cell-${propName}`}
                sx={{ textAlign: "center" }}
              >
                <Typography variant="h5" sx={{ fontWeight: 500 }}>
                  {title}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <MyPatientsPcViewTable patients={patients} onNextPage={onNextPage} />
      </Table>
    </TableContainer>
  );
};
