import React, { useMemo, useCallback, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";

import {
  OrderEnum,
  InputType,
  LoadingEnum,
  StylesEnum,
  GetAllTherapists,
  ProvidedServiceTypes,
  Theories,
  Modality,
} from "@mapsy/shared";
import { AutoCompletePropsOverride, Form } from "interfaces";
import { InputField } from "../atoms/InputField";
import { useModalities } from "hooks/catalogs/useModalities";
import { usePlaces } from "hooks/catalogs/usePlaces";
import { selectReasonState } from "features/reason/reasonSice";
import { useAppSelector } from "hooks";
import { CustomButton } from "components/atoms/Button";
import { DEFAULT_PRICE_RANGE } from "components/organisms/TherapistsList";
import { MapsyPriceRangeSlider } from "components/atoms/PriceRangeSlider";
import { useTheories } from "hooks/catalogs/useTheories";
import { servicesTypes } from "hooks/useGetStartedForms";
import { useInsetBoxShadow } from "hooks/useInsetBoxShadow";

const MIN_DISTANCE_RANGE_PRICE = 100;
interface Props {
  onClearFilters: () => void;
  onFilterTherapists: (
    args: Omit<GetAllTherapists, "getAllTherapists" | "limit">
  ) => void;
}
export const TherapistsFilters: React.FC<Props> = ({
  onClearFilters,
  onFilterTherapists,
}) => {
  const { therapistsLoading } = useAppSelector(selectReasonState);
  const { modalitiesList, modalitiesLoading } = useModalities();
  const { theoriesList, theoriesLoading } = useTheories();
  const { placesList, placesLoading } = usePlaces();
  const isLoading = useMemo(
    () =>
      modalitiesLoading === LoadingEnum.pending ||
      placesLoading === LoadingEnum.pending ||
      therapistsLoading === LoadingEnum.pending ||
      theoriesLoading === LoadingEnum.pending,
    [modalitiesLoading, placesLoading, therapistsLoading, theoriesLoading]
  );
  const { handleScroll, boxShadow } = useInsetBoxShadow({
    vertical: true,
  });

  const [priceOrder, setPriceOrder] = useState<OrderEnum>(OrderEnum.DESC);
  const [priceRange, setPriceRange] = useState(DEFAULT_PRICE_RANGE);
  const [modality, setModality] = useState<Modality | string>("");
  const [theory, setTheory] = useState<Theories | string>("");
  const [locationState, setLocationState] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [serviceType, setServiceType] = useState<ProvidedServiceTypes | string>(
    ""
  );

  const handleChangePriceOrder = useCallback(
    (_pName: string, value: any, _ctx: any) => {
      setPriceOrder(+value as OrderEnum);
    },
    [setPriceOrder]
  );

  const handleSelectModality = useCallback(
    (_pName: string, value: any, _ctx: any) => {
      setModality(value);
    },
    [setModality]
  );

  const handleSelectCity = useCallback(
    (_pName: string, value: any, _ctx: any) => {
      setLocationState(value);
    },
    [setLocationState]
  );

  const handleChangeName = useCallback(
    (_pName: string, value: any, _ctx: any) => {
      setName(value);
    },
    [setLocationState]
  );

  const handleChangeTheory = useCallback(
    (_pName: string, value: any, _ctx: any) => {
      setTheory(value);
    },
    []
  );

  const handleChangeServiceType = useCallback(
    (_pName: string, value: any, _ctx: any) => {
      setServiceType(value);
    },
    []
  );

  const handleChangePriceRange = useCallback(
    (event: Event, newValue: number | number[], activeThumb: number) => {
      if (!Array.isArray(newValue)) {
        return;
      }

      if (activeThumb === 0) {
        setPriceRange([
          Math.min(newValue[0], priceRange[1] - MIN_DISTANCE_RANGE_PRICE),
          priceRange[1],
        ]);
      } else {
        setPriceRange([
          priceRange[0],
          Math.max(newValue[1], priceRange[0] + MIN_DISTANCE_RANGE_PRICE),
        ]);
      }
    },
    [priceRange]
  );

  const handleClearFilters = useCallback(() => {
    setPriceOrder(OrderEnum.DESC);
    setModality("");
    setLocationState("");
    setPriceRange(DEFAULT_PRICE_RANGE);
    setName("");
    setTheory("");
    setServiceType("");
    onClearFilters();
  }, []);

  const handleFilterTherapists = useCallback(() => {
    const params = {
      page: 1,
      order: priceOrder,
      maxPrice: priceRange[1],
      minPrice: priceRange[0],
      state: locationState,
      name,
      modality: modality === "" ? undefined : +modality,
      theory: theory === "" ? undefined : +theory,
      serviceType: serviceType === "" ? undefined : +serviceType,
    };

    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(
        ([_, value]) => value !== undefined && value !== ""
      )
    );

    onFilterTherapists(filteredParams);
  }, [
    priceOrder,
    priceRange,
    locationState,
    modality,
    name,
    theory,
    serviceType,
  ]);

  const inputs: Form = useMemo(
    () => [
      {
        propertyName: "price",
        label: "Precio",
        inputType: InputType.Select,
        value: priceOrder,
        required: false,
        autoFocus: false,
        handleChange: handleChangePriceOrder,
        menuItems: [
          {
            label: "Mayor a menor",
            value: OrderEnum.DESC,
          },
          {
            label: "Menor a mayor",
            value: OrderEnum.ASC,
          },
        ],
        gridSize: {
          xs: 12,
        },
      },
      {
        propertyName: "modality",
        label: "Modalidad de consulta",
        inputType: InputType.Select,
        value: modality,
        required: false,
        autoFocus: false,
        handleChange: handleSelectModality,
        menuItems: [{ label: "Cualquiera", value: "" }, ...modalitiesList],
        gridSize: {
          xs: 12,
        },
      },
      {
        propertyName: "providedService.serviceType",
        label: "Tipo de servicio",
        inputType: InputType.Select,
        value: serviceType,
        required: false,
        autoFocus: false,
        handleChange: handleChangeServiceType,
        menuItems: [{ label: "Cualquiera", value: "" }, ...servicesTypes],
        gridSize: {
          xs: 12,
        },
      },
      {
        propertyName: "state",
        label: "Ciudad",
        inputType: InputType.Autocomplete,
        placeholder: "Ciudad",
        value: locationState,
        required: false,
        autoFocus: false,
        handleChange: handleSelectCity,
        options: placesList,
        gridSize: {
          xs: 12,
        },
      },
      {
        propertyName: "theory",
        label: "Enfoque Terapéutico",
        inputType: InputType.Autocomplete,
        value: theory,
        placeholder: "Enfoque Terapéutico",
        handleChange: handleChangeTheory,
        options: [{ label: "Cualquiera", value: "" }, ...theoriesList],
        autocompleteProps: {
          size: "small",
        } as AutoCompletePropsOverride,
        gridSize: {
          md: 12,
          sm: 12,
          xs: 12,
        },
        validation: {
          isRequired: true,
        },
      },
      {
        propertyName: "name",
        label: "Nombre",
        inputType: InputType.Text,
        placeholder: "Nombre",
        value: name,
        required: false,
        autoFocus: false,
        handleChange: handleChangeName,
        gridSize: {
          xs: 12,
        },
      },
    ],
    [
      priceOrder,
      modality,
      locationState,
      modalitiesList,
      placesList,
      name,
      theory,
      serviceType,
      handleSelectCity,
      handleChangePriceOrder,
      handleSelectModality,
      handleChangeName,
      handleChangeTheory,
      handleChangeServiceType,
    ]
  );

  return (
    <Box
      sx={{
        transition: "250ms all ease",
        position: "relative",
      }}
    >
      <Grid
        container
        sx={{
          justifyContent: "center",
          width: "100%",
          gap: { md: 4, xs: 2 },
          maxHeight: {
            md: "75vh",
            xs: "70vh",
          },
          overflowY: "scroll",
          px: 1,
          pb: 2,
          boxShadow,
          transition: "box-shadow 0.2s",
        }}
        className="transparent-scroll"
        onScroll={handleScroll}
      >
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ fontWeight: 500 }}>
            Filtros
          </Typography>
        </Grid>
        {inputs.map(({ label, ...rest }, i) => (
          <React.Fragment key={`${label}-${i}`}>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {label}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <InputField
                {...rest}
                disabled={isLoading}
                sx={{
                  ".MuiInputBase-input": {
                    p: "1rem",
                    "&.MuiAutocomplete-input": {
                      p: "12px !important",
                    },
                  },
                }}
              />
              {label === "Precio" && (
                <Box sx={{ my: 2, px: 4 }}>
                  <Typography variant="body2">
                    Rango de precio por consulta
                  </Typography>
                  <MapsyPriceRangeSlider
                    getAriaLabel={() => "Price range"}
                    value={priceRange}
                    min={DEFAULT_PRICE_RANGE[0]}
                    max={DEFAULT_PRICE_RANGE[1]}
                    onChange={handleChangePriceRange}
                    valueLabelDisplay="auto"
                    disableSwap
                    title="Rango de precio"
                    step={MIN_DISTANCE_RANGE_PRICE}
                    getAriaValueText={(val) => `$${val}`}
                    marks={Array.from({ length: 5 }).map((_, i) => ({
                      value: i * 500,
                      label: `$${i * 500}`,
                    }))}
                  />
                </Box>
              )}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>

      <Divider sx={{ my: { md: 2, xs: 1 } }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomButton
          onClick={handleClearFilters}
          customStyle={StylesEnum.secondary}
          sx={{
            width: "fit-content",
            whiteSpace: "nowrap",
            px: "8px !important",
            py: "8px !important",
          }}
        >
          Borrar filtros
        </CustomButton>
        <CustomButton
          onClick={handleFilterTherapists}
          customStyle={StylesEnum.primary}
          sx={{
            width: "fit-content",
            whiteSpace: "nowrap",
            px: "12px !important",
            py: "8px !important",
          }}
        >
          Mostrar terapeutas
        </CustomButton>
      </Box>
    </Box>
  );
};
