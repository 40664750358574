import { Appointment, AppointmentStatusSpanish, COLORS } from "@mapsy/shared";
import { Typography } from "@mui/material";
import React from "react";

interface Props {
  appointment: Appointment;
}
export const AppointmentStatusTypography: React.FC<Props> = ({ appointment }) => {
  if (!AppointmentStatusSpanish[appointment.appointmentStatus]) {
    return (
      <Typography variant="body2">
        <Typography
          sx={{ color: COLORS.BLUE_1 }}
          component="span"
          variant="body2"
        >
          Status:
        </Typography>{" "}
        No disponible
      </Typography>
    );
  }

  return (
    <Typography variant="body2">
      <Typography
        sx={{ color: COLORS.BLUE_1 }}
        component="span"
        variant="body2"
      >
        Status:
      </Typography>{" "}
      Cita
      {` ${AppointmentStatusSpanish[appointment.appointmentStatus].toLocaleLowerCase()}`}
    </Typography>
  );
};
