import { UIEvent, useCallback, useState } from "react";

const maxOpacity = 0.5;
const topInsetBoxShadow = "inset 0px 20px 10px -20px";
const bottomInsetBoxShadow = "inset 0px -20px 10px -20px";
const rightInsetBoxShadow = "inset -20px 0 10px -20px";
const leftInsetBoxShadow = "inset 20px 0 10px -20px";

export const useInsetBoxShadow = ({ vertical = true }) => {
  const [scroll, setScroll] = useState(0);
  const [boxShadow, setBoxShadow] = useState("none");

  const handleScroll = useCallback((e: UIEvent<HTMLDivElement>) => {
    const {
      scrollTop,
      scrollLeft,
      scrollWidth,
      clientWidth,
      scrollHeight,
      clientHeight,
    } = e.currentTarget;

    setScroll(vertical ? scrollTop : scrollLeft);

    setBoxShadow(() => {
      if (vertical) {
        const scrollProportion = scrollTop / (scrollHeight - clientHeight);
        const topInsetBoxShadowWithOpacity = `${topInsetBoxShadow} rgba(0,0,0,${scrollProportion * maxOpacity})`;
        const bottomInsetBoxShadowWithOpacity = `${bottomInsetBoxShadow} rgba(0,0,0,${(1 - scrollProportion) * maxOpacity})`;
        return [
          topInsetBoxShadowWithOpacity,
          bottomInsetBoxShadowWithOpacity,
        ].join(", ");
      }

      const scrollProportion = scrollLeft / (scrollWidth - clientWidth);
      const rightInsetBoxShadowWithOpacity = `${rightInsetBoxShadow} rgba(0,0,0,${scrollProportion * maxOpacity})`;
      const leftInsetBoxShadowWithOpacity = `${leftInsetBoxShadow} rgba(0,0,0,${(1 - scrollProportion) * maxOpacity})`;
      return [
        rightInsetBoxShadowWithOpacity,
        leftInsetBoxShadowWithOpacity,
      ].join(", ");
    });
  }, []);

  return { scroll, handleScroll, boxShadow };
};
