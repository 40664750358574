import _ from "lodash";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box, Typography } from "@mui/material";
import {
  NotificationPreferences,
  NotificationPreferencesBySource,
  packNotificationPreference,
  unpackNotificationPreference,
} from "@mapsy/shared";

import { InputField } from "components/atoms/InputField";
import { defaultNotificationPreferences } from "constants/defaultUserValues";
import { HandleChangeType, ProjectedProps } from "interfaces";

export const NotificationsPreferencesInput: React.FC<ProjectedProps<any>> = ({
  handleChange,
  entity,
  inputs,
}) => {
  const [notificationPreferences, setNotificationPreferences] =
    useState<NotificationPreferences>(entity || defaultNotificationPreferences);
  const notificationsBySource: NotificationPreferencesBySource = useMemo(() => {
    const _notifications: NotificationPreferencesBySource = {
      oneDayBeforeAtNoon: {
        email: true,
        whatsapp: true,
        googleCalendar: true,
      },
      oneHourBefore: {
        email: true,
        whatsapp: true,
        googleCalendar: true,
      },
    };

    for (const key in notificationPreferences) {
      const packedBits =
        notificationPreferences[key as keyof NotificationPreferences];

      const [email, whatsapp, googleCalendar] =
        unpackNotificationPreference(packedBits);

      _notifications[key as keyof NotificationPreferences] = {
        email,
        whatsapp,
        googleCalendar,
      };
    }

    return _notifications;
  }, []);

  useEffect(() => {
    setNotificationPreferences(entity);
  }, [entity]);

  const handleChangePreference: HandleChangeType = useCallback(
    (propertyName, value, c) => {
      const newNotificationsBySource = _.set(
        notificationsBySource,
        propertyName,
        value
      );
      
      const firstLevelPath = propertyName.split(
        "."
      )[0] as keyof NotificationPreferencesBySource;
      const { email, whatsapp, googleCalendar } =
        newNotificationsBySource[firstLevelPath];

      const packedBits = packNotificationPreference(
        email,
        whatsapp,
        googleCalendar
      );

      setNotificationPreferences((_notificationPreferences) => ({
        ..._notificationPreferences,
        [firstLevelPath]: packedBits,
      }));

      handleChange(firstLevelPath, packedBits, c);
    },
    [notificationsBySource]
  );

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: { md: 2, xs: 1 },
        width: { md: "75%", xs: "100%" },
      }}
    >
      <Box>
        <Typography variant="h5">Recordatorios por sesión</Typography>
      </Box>
      <Box>
        <Typography variant="h5">Por correo</Typography>
      </Box>
      {inputs?.map(({ gridSize, propertyName, ...rest }, i) => {
        let title: string = "";
        if (propertyName.includes("oneDayBeforeAtNoon")) {
          title = "Un día antes";
        }
        if (propertyName.includes("oneHourBefore")) {
          title = " Una hora antes";
        }

        return (
          <Fragment key={`fragment-notification-${i}`}>
            <Box>
              <Typography variant="body2">{title}</Typography>
            </Box>
            <Box key={`grid-item-topics-${i}`}>
              <InputField
                backgroundMode="transparent"
                propertyName={propertyName}
                value={_.get(notificationsBySource, propertyName, "")}
                handleChange={handleChangePreference}
                {...rest}
              />
            </Box>
          </Fragment>
        );
      })}
    </Box>
  );
};
