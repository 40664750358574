import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

interface Props extends SvgIconProps {}

export const BackArrowIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="13"
        height="10"
        viewBox="0 0 13 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.585937 5.00015L5.29294 9.70715C5.38578 9.79999 5.496 9.87364 5.61731 9.92389C5.73862 9.97414 5.86864 10 5.99994 10C6.13124 10 6.26126 9.97414 6.38256 9.92389C6.50387 9.87364 6.61409 9.79999 6.70694 9.70715C6.79978 9.61431 6.87343 9.50408 6.92368 9.38278C6.97393 9.26147 6.99979 9.13145 6.99979 9.00015C6.99979 8.86885 6.97393 8.73883 6.92368 8.61752C6.87343 8.49622 6.79978 8.386 6.70694 8.29315L4.41394 6.00015L11.9999 6.00015C12.2652 6.00015 12.5195 5.89479 12.707 5.70726C12.8946 5.51972 12.9999 5.26537 12.9999 5.00015C12.9999 4.73493 12.8946 4.48058 12.707 4.29304C12.5195 4.10551 12.2652 4.00015 11.9999 4.00015L4.41394 4.00015L6.70694 1.70715C6.80012 1.6145 6.87407 1.50434 6.92453 1.383C6.97499 1.26167 7.00097 1.13156 7.00097 1.00015C7.00097 0.868743 6.97499 0.738631 6.92453 0.617298C6.87407 0.495965 6.80012 0.385803 6.70694 0.29315C6.51941 0.105678 6.2651 0.000364055 5.99994 0.000364067C5.73477 0.000364079 5.48046 0.105678 5.29294 0.29315L0.585937 5.00015Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};
