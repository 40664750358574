import _ from "lodash";
import { Grid, Typography } from "@mui/material";
import { Patient, Therapist, UserType } from "@mapsy/shared";

import { Form } from "interfaces";
import { InputField } from "components/atoms/InputField";
import COLORS from "constants/colors";
import { capitalizeName } from "utils/capitalize";
import { ChangeProfilePicModal } from "components/atoms/ChangeProfilePicModal";
import { useContext, useState } from "react";
import { ProfilePic } from "components/atoms/ProfilePic";
import { CustomLink } from "components/atoms/Link";
import { ImageCacheContext } from "providers/ImageCacheProvider";
import { AccountStatusSection } from "./AccountStatusSection";
import { ShareLinkSection } from "components/atoms/ShareLinkSection";

type Props = {
  title?: string;
  inputs: Form;
  onPropertyChanged: (propertyName: string, value: any, context: any) => void;
  includeProfilePic?: boolean;
  includePasswordChange?: boolean;
  includeAccountStatus?: boolean;
  separatorBorderAfterInputs?: boolean;
} & (
  | {
      userType: UserType.Patient;
      userData: Patient;
    }
  | {
      userType: UserType.Therapist;
      userData: Therapist;
    }
);

export const ProfileSection: React.FC<Props> = ({
  title,
  inputs,
  userData,
  onPropertyChanged,
  includeProfilePic = false,
  includePasswordChange = false,
  includeAccountStatus,
  userType,
  separatorBorderAfterInputs,
}) => {
  const [changeProfilePic, setChangeProfilePic] = useState(false);
  const [imgUrl, setImgUrl] = useState<string>();
  const { removeCachedImg } = useContext(ImageCacheContext);

  return (
    <>
      {includeAccountStatus && (
        <Grid item xs={12} sx={{ mb: 2 }}>
          <AccountStatusSection
            userType={userType}
            accountStatus={userData.accountStatus}
          />
        </Grid>
      )}
      {includeProfilePic && (
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={3} md={2}>
              <ProfilePic
                {...userData}
                overridenURL={imgUrl}
                editButton={{
                  visible: true,
                  onClick: () => setChangeProfilePic(true),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={10}
              sx={{
                p: 1,
                display: "flex",
                alignItems: "start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {userData.accountStatus.registrationComplete ? (
                <Typography variant="h2">
                  {capitalizeName([
                    userData.firstName,
                    userData.middleName,
                    userData.lastName,
                  ])}
                </Typography>
              ) : (
                <Typography variant="h4">
                  Una vez que{" "}
                  <CustomLink to={"/get-started"} underline variant="h4">
                    completes tus datos
                  </CustomLink>
                  , tu nombre aparecerá aquí.
                </Typography>
              )}
              {userType === UserType.Therapist && (
                <ShareLinkSection therapist={userData} />
              )}
            </Grid>
            <Grid
              item
              sx={{
                borderBottom: `solid 3px ${COLORS.BLUE_1}`,
                my: 2,
              }}
              md={6}
              xs={12}
            ></Grid>
          </Grid>
        </Grid>
      )}

      {title && (
        <Grid
          item
          sx={{
            py: 1,
          }}
          xs={12}
        >
          <Typography variant="h2" sx={{ mb: 1 }}>
            {title}
          </Typography>
        </Grid>
      )}

      {inputs.map(({ gridSize = { sm: 12 }, propertyName, ...rest }, i) => (
        <Grid
          xs={12}
          id={`row-input-container-${propertyName}-${i}`}
          item
          key={`row-input-container-${propertyName}-${i}`}
          sx={{ px: 1, my: 1.5 }}
        >
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs={12} md={4}>
              <Typography variant="h5">{rest.label}:</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <InputField
                backgroundMode="transparent"
                propertyName={propertyName}
                value={_.get(
                  userData as unknown as Record<string, string | number>,
                  propertyName,
                  ""
                )}
                handleChange={onPropertyChanged}
                {...rest}
                label=""
                helperText=""
              />
            </Grid>
          </Grid>
        </Grid>
      ))}

      {includePasswordChange && (
        <Grid item xs={12} sx={{ my: 2, px: 1 }}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Typography variant="h5">Contraseña:</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                p: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography>
                <CustomLink
                  to={`/${{ [UserType.Patient]: "patient", [UserType.Therapist]: "therapist" }[userType]}/change-password`}
                  underline
                >
                  Cambiar contraseña
                </CustomLink>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {separatorBorderAfterInputs && (
        <Grid
          item
          sx={{
            borderBottom: `solid 3px ${COLORS.BLUE_1}`,
            my: 2,
          }}
          md={6}
          xs={12}
        ></Grid>
      )}

      <ChangeProfilePicModal
        isTherapist={userType === UserType.Therapist}
        open={changeProfilePic}
        onClose={(url: string | null) => {
          if (url) {
            removeCachedImg(url);
            setImgUrl(url);
          }
          setChangeProfilePic(false);
        }}
      />
    </>
  );
};
