import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import AmexIcon from "icons/AmexIcon";
import DiscoverIcon from "icons/DiscoverIcon";
import MasterCardIcon from "icons/MasterCardIcon";
import OtherCardIcon from "icons/OtherCardIcon";
import VisaIcon from "icons/VISAIcon";
import { CardNumberType } from "interfaces";
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { formatCardNumber, getCardNumberType } from "utils/cardUtils";

type Props = TextFieldProps;

const cardNumberTypeIcons: Record<CardNumberType, ReactNode> = {
  visa: <VisaIcon />,
  mastercard: <MasterCardIcon />,
  amex: <AmexIcon />,
  discover: <DiscoverIcon />,
  other: <OtherCardIcon />,
};

export const CardNumberInput: React.FC<Props> = ({
  onChange,
  value,
  ...textFieldProps
}) => {
  const [cardNumberType, setCardNumberType] = useState<CardNumberType>("other");

  useEffect(() => {
    if (!value) {
      setCardNumberType("other");
      return;
    }

    setCardNumberType(getCardNumberType(String(value)));
  }, [value]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      e.target.value = formatCardNumber(e.target.value);
      onChange?.(e);
    },
    [onChange]
  );

  return (
    <TextField
      value={value}
      onChange={handleChange}
      multiline={false}
      {...textFieldProps}
      InputProps={{
        ...textFieldProps.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            {cardNumberTypeIcons[cardNumberType]}
          </InputAdornment>
        ),
      }}
      inputProps={{
        maxLength: 19,
      }}
    />
  );
};
