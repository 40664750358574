import React, { useCallback } from "react";
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { Action, COLORS } from "@mapsy/shared";
import { CustomLink } from "components/atoms/Link";
import { theme } from "theme";
import { BackArrowIcon } from "icons/BackArrowIcon";
import { useNavigate } from "react-router-dom";
import { useAnalytics } from "hooks/useAnalytics";
import COMPONENTS from "constants/componentNames";

interface Props {
  showAllPatientsHeader: boolean;
  patientName: string;
}
export const MyPatientsHeader: React.FC<Props> = ({
  showAllPatientsHeader,
  patientName,
}) => {
  const nav = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { createAnalytic } = useAnalytics();

  const handleBackArrowClick = useCallback(() => {
    nav("/therapist/my_patients");
    createAnalytic({
      action: Action.CLICK,
      componentName: COMPONENTS.PATIENT_APPOINTMENT_LIST,
    });
  }, []);

  if (showAllPatientsHeader) {
    return (
      <Typography
        variant="h3"
        sx={{
          width: "100%",
          textAlign: { xs: "center", md: "left" },
          color: { xs: COLORS.BLUE_1, md: "black" },
          fontWeight: { xs: 500, md: 400 },
        }}
      >
        Mis pacientes
      </Typography>
    );
  }

  if (isMobile) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              width: "100%",
              textAlign: "center",
              color: COLORS.BLUE_1,
              fontWeight: 500,
            }}
          >
            Historial de Citas
          </Typography>
          <Typography
            component="span"
            variant="h3"
            sx={{
              width: "100%",
              textAlign: "center",
              color: COLORS.BLUE_1,
              fontWeight: 500,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {patientName}
          </Typography>
        </Box>
        <Box
          sx={{ position: "absolute", top: "-3.1rem" }}
          aria-label="lista de pacientes"
        >
          <IconButton onClick={handleBackArrowClick}>
            <BackArrowIcon />
          </IconButton>
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: { md: 2, xs: 1 },
        width: "min-content",
      }}
    >
      <CustomLink
        to={"/therapist/my_patients"}
        underline
        analyticsLabel="Mis pacientes"
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: { xs: "center", md: "left" },
            color: { xs: COLORS.BLUE_1, md: "black" },
            fontWeight: { xs: 500, md: 400 },
          }}
        >
          Mis pacientes
        </Typography>
      </CustomLink>
      <ArrowForwardIosIcon sx={{ fontSize: { md: "1.2rem", xs: "1rem" } }} />
      <Typography
        variant="h3"
        sx={{
          width: { xs: "45%", md: "100%" },
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        Historial de citas: {patientName}
      </Typography>
    </Box>
  );
};
