import {
  APINames,
  Appointment,
  FindAvailableApppointmentsParams,
  GetAppointmentsParams,
  OrderEnum,
  PaymentStatus,
} from "../..";

import BaseAPI from "./BaseEndpointAPI";

class AppointmentAPI extends BaseAPI {
  constructor() {
    super(APINames.APPOINTMENT);
  }

  byTherapist(
    query: GetAppointmentsParams<keyof Appointment>,
    therapistId: string
  ) {
    const queryString = new URLSearchParams(query as any).toString();
    return `${this.baseURL}/${therapistId}/therapist?${queryString}`;
  }

  byPatient(
    query: GetAppointmentsParams<keyof Appointment>,
    patientId: string
  ) {
    const queryString = new URLSearchParams(query as any).toString();
    return `${this.baseURL}/${patientId}/patient?${queryString}`;
  }

  availableTimeByDay(query: FindAvailableApppointmentsParams) {
    const queryString = new URLSearchParams(query as any).toString();
    return `${this.baseURL}/availableTimeByDay?${queryString}`;
  }

  getAll(params: GetAppointmentsParams<Appointment>) {
    const queryString = new URLSearchParams(
      params as Record<string, any>
    ).toString();
    return `${this.baseURL}?${queryString}`;
  }

  byPatientIdAndById(apptId: string, patientId: string) {
    return `${this.baseURL}/${apptId}/patient/${patientId}`;
  }

  updatePaymentStatus(apptId: string, query: { paymentStatus: PaymentStatus }) {
    const queryString = new URLSearchParams(query as any).toString();
    return `${this.baseURL}/${apptId}/payment-status?${queryString}`;
  }
}

const api = new AppointmentAPI();

export default api;
