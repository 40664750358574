
import React from "react";
import { IconsProps } from "interfaces";

export const FileIcon: React.FC<IconsProps> = ({
  width = 24,
  height = 36,
  fillColor = "#0F91D2",
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 21C18 21.7956 17.6839 22.5587 17.1213 23.1213C16.5587 23.6839 15.7956 24 15 24H3C2.20435 24 1.44129 23.6839 0.87868 23.1213C0.31607 22.5587 0 21.7956 0 21V3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0L11.25 0L18 6.75V21ZM3 1.5C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V18L4.836 14.664C4.95422 14.5461 5.10843 14.4709 5.27417 14.4506C5.43992 14.4302 5.60773 14.4657 5.751 14.5515L9 16.5L12.2355 11.97C12.2988 11.8815 12.3806 11.8078 12.4753 11.754C12.5699 11.7003 12.6751 11.6678 12.7836 11.6588C12.8921 11.6498 13.0012 11.6645 13.1034 11.702C13.2056 11.7394 13.2985 11.7986 13.3755 11.8755L16.5 15V6.75H13.5C12.9033 6.75 12.331 6.51295 11.909 6.09099C11.4871 5.66903 11.25 5.09674 11.25 4.5V1.5H3Z" fill={fillColor}/>
    </svg>
  );
};
