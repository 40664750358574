import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Box, Breakpoint, Container, IconButton } from "@mui/material";
import COLORS from "constants/colors";
import close from "../assets/svg/close.svg";
import { LoadingOverlay } from "components/atoms/LoadingOverlay";
import { CloseIcon } from "icons/CloseIcon";

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
  maxWidthContainer?: Breakpoint;
  showLoadingOverlay?: boolean;
}
export const ModalLayout: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  maxWidthContainer = "md",
  showLoadingOverlay = false,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "rgba(0,0,0,0.1)",
        position: "fixed",
        width: "100%",
        height: "100vh",
        top: 0,
        left: 0,
        zIndex: 1051, //as buttons wrapper in Fab component (MUI) has a position of 1050, this should be bigger
      }}
      onClick={onClose}
      className={`custom-modal ${isOpen ? "open" : ""}`}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Container
          maxWidth={maxWidthContainer}
          sx={(theme) => ({
            backgroundColor: COLORS.WHITE,
            borderRadius: "10px",
            px: { md: "1.5rem", xs: "0.5rem" },
            py: "1rem",
            position: "relative",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            maxHeight: "calc(100vh - 4rem)",
            overflowY: "auto",
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <LoadingOverlay show={showLoadingOverlay} />
          <Box sx={{ display: "flex", justifyContent: "right", pr: 1, py: 1 }}>
            <IconButton className="close-button" onClick={onClose}>
              <CloseIcon sx={{ fontSize: { xs: "22px", md: "22px" } }} />
            </IconButton>
          </Box>
          {children}
        </Container>
      </Box>
    </Box>
  );
};
